
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from './text-truncate.pipe';
import { TextTrimPipe } from './text-trim.pipe';
import { SumPipe } from './sum.pipe';
import { ReplaceStringPipe } from './replace-string.pipe';
import { DatesDifferenceInDaysPipe } from './dates-difference-in-days.pipe';
import { ShortUserNamePipe } from './short-user-name.pipe';
import { DetectUrlPipe } from './detect-url.pipe';

@NgModule({
  declarations: [TruncatePipe, TextTrimPipe, SumPipe, ReplaceStringPipe, DatesDifferenceInDaysPipe, ShortUserNamePipe, DetectUrlPipe],
  imports: [
    CommonModule
  ], exports: [TruncatePipe, TextTrimPipe, SumPipe, ReplaceStringPipe, DatesDifferenceInDaysPipe, ShortUserNamePipe, DetectUrlPipe]
})
export class PipesModule { }
