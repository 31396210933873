import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './auth.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private myRoute: Router, private AuthService: AuthService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const loginToken = this.AuthService.getBarereToken();
    
    
    let isSubtraidEmployee = false;

    if (loginToken) {
      if (loginToken.hasOwnProperty('isSubtraidEmployee'))
        isSubtraidEmployee = true;
    }

    if (this.AuthService.isLogin() && !isSubtraidEmployee && !this.AuthService.isStakeholderUser()) {
      

      return true;
    } else {
      
      this.myRoute.navigate(["login"]);
      return false;
    }
  }
}
