import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment.uat';


@Injectable()
export class NotificationService implements Resolve<any> {

    basePath = environment.basePath;
    notificationsArray = [];
    showPanel = false;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,

    ) { }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.notificationsArray = [];
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getNotifications(5, 0)
            ]).then(
                () => {
                    return resolve(this.notificationsArray);
                },
                reject
            );
        });
    }

    /**
     * get notifications
     *
     * @param {limit} integer
     * @param {skip} integer
     * @returns {Observable<any> | Promise<any> | any}
     */
    getNotifications(limit, skip): Promise<any[]> {

        const endPoint: string = this.basePath + `/users/notifications?limit=${limit}&skip=${skip}`;

        return new Promise((resolve, reject) => {

            this._httpClient.get(endPoint)
                .subscribe((data: any) => {
                    this.notificationsArray = data.notifications;
                    resolve(this.notificationsArray);
                }, reject);
        });
    }

    /**
     * update a notification as seen
     * @param id : notification id
     * @returns : observable
     */
    updateNotification(id: string): Observable<any> {
        const url = `${this.basePath}/notifications/seen/${id}`;
        return this._httpClient.patch(url, {});
    }

    /**
     * mark set of notifications as seen
     * @param obj : notifications array
     * @returns : observable
     */
    marlAllAsSeen(obj: any): Observable<any> {
        const url = `${this.basePath}/notifications/seen`;
        return this._httpClient.patch(url, obj);
    }



    /**
     * delete notifications
     * @param obj : notification ids
     * @returns : observable
     */
    deleteAll(obj: any): Observable<any> {

        const url = `${this.basePath}/notifications`;
        return this._httpClient.request('delete', url, { body: obj });
    }

    /**
     * get unseen notification count
     * @returns : observable
     */
    getNotificationCount(): Observable<any> {

        let endPoint = this.basePath + "/users/notifications-count";

        return this._httpClient.get(endPoint);
    }

}
