import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthStakeholderGuard implements CanActivate, CanLoad {
  constructor(
    private router: Router,
    private AuthService: AuthService
  ) { }
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.AuthService.isLogin() && this.AuthService.isStakeholderUser()) {  
      return true;
    } else {      
      this.router.navigate(["stakeholder-login"]);
      return false;
    }
  }
  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.AuthService.isStakeholderUser())
      return true;
    else {
      this.router.navigate(['stakeholder-login']);
      return false;
    }
  }
}
