// src/app/auth/token.interceptor.ts

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {AuthService} from './auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.method !== 'PUT') {
      // Assuming the only put request that is ever needed is to the ams s3 bucket
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.barereToken}`
        }
      });
    }

    return next.handle(request);
  }
}
