import { FuseUtils } from '@fuse/utils';

export class Contact {
    id: string;
    name: string;
    lastName: string;
    avatar: string;
    nickname: string;
    company: string;
    jobTitle: string;
    email: string;
    phone: string;
    address: string;
    birthday: string;
    notes: string;
    location: string;
    isAvailable: string;
    isFavourite: boolean;
    accountType: string;

    //a5e
    employeeRole: string;
    companyName: string;
    employerId: string;
    //a5e end

    /**
     * Constructor
     *
     * @param contact
     */
    constructor(contact) {
        {
            this.id = contact._id
            this.name = contact.name || '';
            this.lastName = contact.lastName || '';
            this.avatar = contact.img || '5cd1c47a364cf13bd402c461';
            this.nickname = contact.nickname || '';
            this.company = contact.company || '';
            this.jobTitle = contact.jobTitle || '';
            this.email = contact.email || '';
            this.phone = contact.phone || '';
            this.location = contact.location && contact.location[0] ? contact.location[0].country : '';
            this.birthday = contact.birthday || '';
            this.notes = contact.notes || '';
            this.jobTitle = contact.jobTitle || '';
            this.accountType = contact.accountType || '';
            this.isAvailable = contact.isAvailable || false;
            this.isFavourite = contact.isFavourite || false;

            //a5e
            this.employeeRole = contact.employeeRole || '';
            this.companyName = contact.companyName || '';
            this.employerId = contact.employerId || null;
            //a5e end
        }
    }
}
