import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datesDifferenceInDays'
})
export class DatesDifferenceInDaysPipe implements PipeTransform {

  /**
   * Returns dates difference in days
   * @param pastDate : any
   * @param futureDate : any
   * @returns {number}
   */
  transform(pastDate: any, futureDate?: any): number {
    futureDate = futureDate || new Date();
    let diffMillisec = Number(new Date(futureDate)) - Number(new Date(pastDate));
    return Math.round(diffMillisec / (1000 * 3600 * 24));

  }

}
