import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CustomValidationService {

  constructor() { }

  /**
   * White space validator
   * @param minLength : number
   * @returns {ValidationErrors | null}
   */
  whitespaceValidator(minLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let textLength = (control.value || '').trim().length;
      return textLength == 0 ? { 'whitespace': true } : (textLength < minLength ? { 'minimumLength': true } : null);
    }
  }

  /**
   * Validate amount
   * @returns {ValidationErrors | null}
   */
  validateAmount(): ValidatorFn {   //validate for 0 and -ve values
    return (control: AbstractControl): ValidationErrors | null => {
      if (parseFloat(control.value) == 0) {
        return {
          amountInvalid: true
        };
      }
      else if (Math.sign(parseFloat(control.value)) == -1) {
        return {
          amountInvalid: true
        };
      }
      // else if (parseFloat(control.value) < 0) {
      //   return {
      //     amountInvalid: true
      //   };
      // }

      return null;
    }
  }

  /**
   * Restrict for non-amount characters
   * @param e : any
   * @returns 
   */
  amountOnly(e) {

    if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {

      return;
    }

    //set character position
    var value = e.target.value,
      idx = e.target.selectionStart,
      key = e.key;
    value = value.slice(0, idx) + key + value.slice(idx + Math.abs(0));
    if (value && !value.match(new RegExp(/^[0-9]*(\.[0-9]{0,2})?$/)))
      e.preventDefault();

  }

  /**
   * Validate a date range
   * @param otherDate : string
   * @param startDateKey : string
   * @returns {ValidationErrors | null}
   */
  dateRangeValidator(otherDate: string, startDateKey: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      if (!(control.parent.get(otherDate).value == null || control.parent.get(otherDate).value == '') && !(control.value == null || control.value == '')) {

        if (otherDate == startDateKey) {
          if (new Date(control.parent.get(otherDate).value) > new Date(control.value))
            return { dates: "End Date is less than Start Date." };
        }
        else {
          if (new Date(control.parent.get(otherDate).value) < new Date(control.value))
            return { dates: "Start Date is greater than End Date." };
        }
      }
      else {
        if (control.value == null || control.value == '')
          return { required: true };
      }
      if (control.parent.get(otherDate).hasError('dates')) {
        control.parent.get(otherDate).setErrors({
          dates: null
        });
        control.parent.get(otherDate).updateValueAndValidity();
      }
      return null;
    }
  }

  /**
   * Allow number character only
   * @param event : any
   * @returns {boolean}
   */
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  /**
   * Validate url
   * @returns {ValidationErrors | null}
   */
  validateUrl(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      var pattern = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
      return pattern.test(control.value || '') ? null : { 'invalidUrl': true };
    }
  }

}
