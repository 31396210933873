import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appFileDragAndDrop]'
})
export class FileDragAndDropDirective {

  @Output() onFileDrop = new EventEmitter<any>();
  @HostBinding('style.background-color') background = '#ffffff';
  @HostBinding('style.opacity') private opacity = '1';

  constructor() { }


  /**
   * Called on file drag over
   * @param evt : any
   */
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.background = '#edebeb';
    this.opacity = '0.4';
  }

  /**
   * Called on file drag leave
   * @param evt : any
   */
  @HostListener('dragleave', ['$event']) onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.background = '#ffffff';
    this.opacity = '1';
  }

  /**
   * Called on file drop event
   * @param evt : any
   */
  @HostListener('drop', ['$event']) ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#ffffff';
    this.opacity = '1';
    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.onFileDrop.emit(files);

    }
  }

}
