import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './auth.service'
import { DecodedToken } from '../models/decoded-token';

@Injectable({
  providedIn: 'root'
})
export class AfterLoginGuard implements CanActivate {

  constructor(private myRoute: Router, private AuthService: AuthService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


    const loginToken: DecodedToken = this.AuthService.getBarereToken();


    let isSubtraidEmployee = false;
    let isExternalUser = false;

    if (loginToken) {
      if (loginToken.hasOwnProperty('isSubtraidEmployee'))
        isSubtraidEmployee = true;
      else if (loginToken.accountType == "external")
        isExternalUser = true;
    }

    if (this.AuthService.isLogin()) {
      if (!isSubtraidEmployee && !isExternalUser) {
        this.myRoute.navigate(['dashboard']);
        return false;
      } else {

        if (isSubtraidEmployee) {
          this.myRoute.navigate(['subtraid-employee']);
          return false;
        }
        else if (isExternalUser) {
          this.myRoute.navigate(['stakeholder-users']);
          return false;
        }
        else
          return true;
      }
    }
    else return true;

  }
}
