import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.uat';

@Injectable( {
    providedIn: 'root'
} )
export class UploadService {

    basePath = environment.basePath;
    constructor (
        private http: HttpClient,
        private myRouter: Router
    ) { }


    /**
   * @function
   *    Api call to the '/upload' endpoint to create media object on the backend and prepare for single upload
   * @param
   *    payLoad : object , string : type , string : ext
   * @return
   *    Observable
   */
    singleUpload ( payload ) {

        //'x-amz-server-side​-encryption' : 'aws:kms',

        const endPoint: string = '/media';
        return this.http.post( this.basePath + endPoint, payload );

    }

    /**
     * Upload file to AWS S3
     * @param body : File
     * @param presignedUrl : string
     * @param type : string
     * @returns {Observable}
     */
    putFileToS3 ( body: File, presignedUrl: string, type: string ) {


        return this.http.put( presignedUrl, body, {
            responseType: "blob", reportProgress: true, observe: "events", headers: new HttpHeaders(
                {
                    'Content-Type': type,
                    'x-amz-server-side-encryption': "aws:kms"
                },
            )
        } );
    }

    /**
     * Get file
     * @param id : string
     * @returns {Observable<any>}
     */
    getFile ( id ): Observable<any> {
        const endPoint: string = '/media';
        return this.http.get( this.basePath + endPoint + '/' + id, { responseType: 'blob' } );
    }

}
