import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class StakeholderUserGuard implements CanActivate {

  constructor(
    private router: Router,
    private AuthService: AuthService
  ) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.AuthService.isStakeholderUser())
      return true;
    else {
      this.router.navigate(['stakeholder-login']);
      return false;
    }
  }

}
