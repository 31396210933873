import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { environment } from '../../../environments/environment.uat';

import { User } from '../models/users';

import * as jwt_decode from '../../../../node_modules/jwt-decode';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { BaseService } from '../services/base.service';
import { OnesignalService } from './onesignal.service';


@Injectable()

export class AuthService implements OnDestroy {

  basePath = environment.basePath;

  private currentUser = new BehaviorSubject<any>(new User({}));

  user = this.currentUser.asObservable();
  firstTimeLogin: boolean = false;


  private _unsubscribe = new Subject();
  constructor(
    private http: HttpClient,
    private myRouter: Router,
    private _fuseConfigService: FuseConfigService,
    private baseService: BaseService,
    // private onesignalService: OnesignalService,
  ) {

  }

  /**
   * Lifecycle hook: OnDestroy
   */
  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
  /**
 * @function
 *    Use HttpClient to make registration request
 * @param
 *    Object : UserInfo , String : reigister type (users/companies)
 * @return
 *    Observable
 */
  register(userInfo) {

    const endPoint: string = '/' + 'users' + '/register';
    return this.http.post(this.basePath + endPoint, userInfo);

  }

  /**
  * @function
  *    Use HttpClient to make login request
  * @param
  *    Object : loginInfo
  * @return
  *    Observable
  */
  login(loginInfo) {
    this.firstTimeLogin = true;
    const endPoint: string = '/' + 'users' + '/login';
    return this.http.post(this.basePath + endPoint, loginInfo);
  }

  /**
  * @function
  *    Clear user's barereToken in the localStorage and redirect user back to login page
  * @param
  * @return
  **/

  logOut() {
    let route = '';
    this.isSubtraidEmployee() ? route = 'admin-login' : (this.isStakeholderUser() ? route = 'stakeholder-login' : route = 'login');
    // End of reseting the tag on onesignal database to prevent getting incorrect push notification
    localStorage.removeItem('barereToken');
    sessionStorage.removeItem('seenWarning');
    // sessionStorage.removeItem('seenIncomplete');
    // sessionStorage.removeItem('seenInfo');
    // sessionStorage.removeItem('selectedProject');

    this.currentUser.next(new User({}));

    // onsignal clear tag
    var OneSignal = window['OneSignal'] || [];

    OneSignal.push(function () {
      OneSignal.deleteTags(["name", "_id", "active"]);

    });

    // this.onesignalService.clearTag();

    sessionStorage.clear();

    this.myRouter.navigate([route]);
    setTimeout(() => {
      window.location.reload();
    }, 100);






  }


  /**
  * @function
  *    Use HttpClient to make forgotToken request
  * @param
  *    Object : loginInfo
  * @return
  *    Observable
  */
  restpasstoken(email) {
    const endPoint: string = '/users/resetpasstoken';
    return this.http.post(this.basePath + endPoint, email);
  }


  /**
  * @function
  *    Using barereToken's exp to check if the user still have a valid login session
  *    if the barereToken doesn't exsist return false
  *    else check the time expireDate of the barereToken
  * @param
  * @return
  *    boolean
  **/

  isLogin() {

    let decodedToken = this.getBarereToken();

    if (decodedToken) {
      let expireDate = decodedToken.exp;

      return Math.floor(Date.now() / 1000) < expireDate;
    } else {
      return false;
    }

  }

  /**
   * Checks if Subtraid Employee is logged-in
   * @returns {boolean}
   */
  isSubtraidEmployee() {
    const loginToken = this.getBarereToken();
    if (loginToken) {
      if (loginToken.hasOwnProperty('isSubtraidEmployee'))
        return true;
    }
    return false;
  }

  /**
   * Checks if stakeholder user is logged-in
   * @returns {boolean}
   */
  isStakeholderUser() {
    const loginToken = this.getBarereToken();
    if (loginToken) {
      if (loginToken.accountType == "external")
        return true;
    }
    return false;
  }

  /**
  * @function
  *   Grab the barereToken from the local storage and use the Jwt_decode libary to decode the token
  * @param
  * @return
  *    Object Token
  **/

  getBarereToken() {
    try {
      return jwt_decode(localStorage.barereToken);

    }
    catch (Error) {
      return null;
    }
  }

  /**
   * Refresh User
   */
  refreshUser() {
    if (this.getBarereToken() === null) return;
    let issubtraid = this.isSubtraidEmployee();
    if (!issubtraid) {
      this.getUser()
        .pipe(takeUntil(this._unsubscribe))
        .subscribe(
          (data: any) => {
            if (data != null) {
              let updateUser = new User(data.user);

              this.currentUser.next(updateUser);
            }
          },
          (err) => {
            console.log(err)
          }
        )
    }
    else if (issubtraid) {
      let euser = new User({});
      let token = this.getBarereToken();
      euser._id = token._id;
      euser.accountType = token.accountType;
      euser.email = token.email;
      euser.isSubtraid = true;
      euser.name = token.name;
      this.currentUser.next(euser);
    }
    else {
      let euser = new User({});
      let token = this.getBarereToken();
      euser._id = token._id;
      euser.accountType = token.accountType;
      euser.email = token.email;
      euser.name = token.name;
      euser.role = token.defaultRole;
      euser.img = token.img;
      euser.employerAndCompanyDetail = token.companyName;
      this.currentUser.next(euser);
    }
  }

  /**
   * Get User's status
   * @returns {Observable}
   */
  getUserStatus() {
    return this.baseService.get(this.basePath + '/companies/employee/status', true);
  }

  /**
   * Get Subtraid employee's status
   * @returns {Observable}
   */
  getSubtraidEmployeeStatus() {
    return this.baseService.get(this.basePath + '/subtraid-employees/status', true);
  }

  /**
   * Get User
   * @returns {Observable | null}
   */
  getUser() {
    try {
      let decoded = jwt_decode(localStorage.barereToken);
      const endPoint: string = '/users/' + decoded._id;
      return this.http.get(this.basePath + endPoint);
    }
    catch (Error) {

      return null;
    }
  }

  /**
   * Reset User object
   */
  resetUser() {
    this.currentUser.next(new User({}));
  }

  // sendEmail(data) {
  //   let path = "https://api.postmarkapp.com/email";
  //   let header: any = {
  //     headers: {

  //       "X-Postmark-Server-Token": "d158e816-db9e-47e1-9538-b8503246df91"
  //     }
  //   };

  //   return this.http.post(path, data, header);
  // }

  // sendEmailWithTemplate(data) {
  //   let path = "https://api.postmarkapp.com/email/withTemplate/";
  //   let header: any = {
  //     headers: {

  //       "X-Postmark-Server-Token": "d158e816-db9e-47e1-9538-b8503246df91"
  //     }
  //   };

  //   return this.http.post(path, data, header);
  // }

}
