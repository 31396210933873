import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-delete',
  templateUrl: './dialog-delete.component.html',
  styleUrls: ['./dialog-delete.component.scss']
})
export class DialogDeleteComponent implements OnInit {

  message: string;
  showMsgPart: boolean = true;

  constructor(
    public matDialogRef: MatDialogRef<DialogDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.message = this.data.message;
    this.showMsgPart = ('showMsgPart' in this.data) ? this.data.showMsgPart : true;
  }

  ngOnInit() {
  }

  /**
   * Close this dialog box when user cancels action
   */
  onNoClick(): void {
    this.matDialogRef.close();
  }

  /**
   * Close this dialog box when user confirms action
   */
  close(): void {
    this.matDialogRef.close({ action: true });
  }
}
