import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceString'
})
export class ReplaceStringPipe implements PipeTransform {

  /**
   * Replace a string in a value
   * @param value : string - source string
   * @param target : string
   * @param replaceWith : string
   * @returns {string | null}
   */
  transform(value: string, target: string, replaceWith: string): string {
    if (value != null) {
      let tval = new RegExp(target, "g");
      return value.replace(tval, replaceWith);
    }
    else return null;

  }

}
