import { PermissionService } from './../../../../../app/main/services/permission.service';
import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';


import { FuseNavigationItem } from '@fuse/types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

@Component( {
    selector: 'fuse-nav-vertical-item',
    templateUrl: './item.component.html',
    styleUrls: [ './item.component.scss' ]
} )
export class FuseNavVerticalItemComponent implements OnInit, OnDestroy {
    @HostBinding( 'class' )
    classes = 'nav-item';

    @Input()
    item: FuseNavigationItem;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     */

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor (
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        public permissionService: PermissionService,
        private iconRegistry: MatIconRegistry,
        private sanitizer : DomSanitizer
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        // this.iconRegistry.addSvgIcon('subtraid-menu', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/custom-icons2/subtraid-menu.svg'));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe( takeUntil( this._unsubscribeAll ) )
            .subscribe( () => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            } );
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
