import { Component, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef , Input , AfterViewInit , Output , EventEmitter} from '@angular/core';
import { NgForm }   from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { map , debounceTime} from 'rxjs/operators';
import { defer , fromEvent } from 'rxjs';


// environment & constant
import { environment } from 'environments/environment.uat';
import {Constants} from '../../constants';


@Component({
  selector: 'app-payment-button',
  templateUrl: './payment-button.component.html',
  styleUrls: ['./payment-button.component.scss']
})

export class PaymentButtonComponent implements AfterViewInit
 {

    //inputs
    @Input() textCopy: string;
    @Input() private stripePlanId : string;
    @Input() disable: boolean = true;

    @Output()
    errorEmitter: EventEmitter<any>;

    @ViewChild('stripeBtn') stripeButton;

    stripeButtonClick$ = defer(() => fromEvent(this.stripeButton._elementRef.nativeElement, 'click')); // listen for clicks

    basePath = Constants.basePath;
    stripe:any;

    constructor(private httpClient: HttpClient) {

      //stripe init
      this.stripe = Stripe(environment.stripeId);
      this.errorEmitter = new EventEmitter();
    }

    ngAfterViewInit() {

      this.stripeButtonClick$.pipe(

        debounceTime(400)

      ).subscribe(()=>{

        if( !this.stripePlanId ){
          this.errorEmitter.next("Something went wrong, Please try again in a few minutes or contact the customer support.");
          console.log("Error: Missing stripePlanId. Refrecnce : payment Button.")
          return
        }

        this.httpClient.post(this.basePath + '/stripe/create_checkout_session' , { planId: this.stripePlanId})
            .subscribe((data: any) => {
                this.stripe.redirectToCheckout({
                      // Make the id field from the Checkout Session creation API response
                      // available to this file, so you can provide it as parameter here
                      // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                      sessionId: data.session.id
                  }).then(function (result) {
                    // If `redirectToCheckout` fails due to a browser or network
                    // error, display the localized error message to your customer
                      // using `result.error.message`.
                  });
            },
            (err) =>{
              let errorMessage = err.message;
              this.errorEmitter.next(errorMessage);
            }
          ) // scribe
      });
    }
}
