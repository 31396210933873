import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortUserName'
})
export class ShortUserNamePipe implements PipeTransform {

  /**
   * Get first characters from first name and last name of a User
   * e.g. User=Sarita Verma, returns SV
   * @param username : string
   * @returns {string | null}
   */
  transform(username: string): any {
    let user = username.trim();
    if (user != '') {
      let temp = user.split(' ');
      return (user[0] + (temp.length > 1 ? temp[temp.length - 1][0] : '')).toUpperCase();
    }

    return null;
  }

}
