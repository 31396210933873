import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Injectable, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DynamicOverlayService } from '../component/loader/dynamic-overlay.service';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoaderComponent } from '../component/loader/loader.component';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  private topBarButton = new BehaviorSubject(false);
  public showButton = this.topBarButton.asObservable();

  private timesheets = new BehaviorSubject([]);
  public getTimesheets = this.timesheets.asObservable();

  private onFMAccountChange = new BehaviorSubject<FileManagerAccount | null>(null);
  public fileManagerAccount = this.onFMAccountChange.asObservable();

  private onChatTourStart = new BehaviorSubject(null);
  public chatTourStarted = this.onChatTourStart.asObservable();

  private onCompanyTourStart = new BehaviorSubject(null);
  public companyTourStarted = this.onCompanyTourStart.asObservable();

  private onTimesheetTourStart = new BehaviorSubject(null);
  public timesheetTourStarted = this.onTimesheetTourStart.asObservable();

  private onProfileTourStart = new BehaviorSubject(null);
  public profileTourStarted = this.onProfileTourStart.asObservable();

  loaderOverlayRef: OverlayRef;

  constructor(
    private dynamicOverlay: DynamicOverlayService,
    private overlay: Overlay,
  ) { }

  /**
   * Used in toolbar's elements visiblity status
   * @param status : boolean
   */
  changeVisibility(status: boolean) {

    this.topBarButton.next(status);
  }

  /**
   * 
   * @param data : any
   */
  changeTimesheetList(data: any) {
    this.timesheets.next(data);
  }

  /**
   * Update File Manager Account
   * @param data : FileManagerAccount
   */
  updateFMAccount(data: FileManagerAccount) {
    this.onFMAccountChange.next(data);
  }

  /**
   * Start chat tour
   * @param data : boolean
   */
  startChatTour(data: boolean) {
    this.onChatTourStart.next(data);
  }

  /**
   * Start company tour
   * @param data : boolean
   */
  startCompanyTour(data: boolean) {
    this.onCompanyTourStart.next(data);
  }

  /**
   * Start Timesheet tour
   * @param data : boolean
   */
  startTimesheetTour(data: boolean) {
    this.onTimesheetTourStart.next(data);
  }

  /**
   * Start Profile tour
   * @param data : boolean
   */
  startProfileTour(data: boolean) {
    this.onProfileTourStart.next(data);
  }

  /**
   * Convert file size to KB,MB,...
   * @param bytes : number
   * @returns {string}
   */
  fileSize(bytes) {
    var exp = Math.log(bytes) / Math.log(1024) | 0;
    var result = (bytes / Math.pow(1024, exp)).toFixed(2);

    return result + ' ' + (exp == 0 ? 'bytes' : 'KMGTPEZY'[exp - 1] + 'B');
  }

  /**
  * get file extension to display icon
  * @param fileType : mime type of the file
  * @returns : extension icon image name
  */
  getExtension(filetype) {

    if (filetype.includes('image'))
      return 'image';
    else if (filetype.includes('pdf'))
      return 'pdf';
    else if (filetype.includes('ms-excel') || filetype.includes('sheet'))
      return 'xls';
    else if (filetype.includes('msword') || filetype.includes('document'))
      return 'doc';
    else
      return 'general';
  }

  /**
   * scroll to bottom of an element
   * @param element : HTML element
   */
  scrollToBottom(element: ElementRef) {
    try {
      element.nativeElement.scrollTop = element.nativeElement.scrollHeight;
    }
    catch (e) { }
  }

  /**
   * show or hide loader/spinner with text on top of any element or whole page
   * @param action : text to show like Sending...
   * @param show : true - to show loader. false- to hide loader 
   * @param element : html element on which loader will be displayed. If null then it will appear on whole page
   * @param backdropClass : custom backdrop class if element parameter is passed
   */
  showHideLoader(action: string, show: boolean, element?: ElementRef, backdropClass?: string) {
    if (show) {
      if (element)
        this.loaderOverlayRef = this.dynamicOverlay.createWithDefaultConfig(element.nativeElement, backdropClass ? backdropClass : 'bgdrop2');
      else
        this.loaderOverlayRef = this.overlay.create({
          positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
          hasBackdrop: true,
          backdropClass: 'bgdrop'
        });
      const cref = this.loaderOverlayRef.attach(new ComponentPortal(LoaderComponent));
      cref.instance.action = action;
    }
    else {

      if (this.loaderOverlayRef) {
        this.loaderOverlayRef.detach();
      }
    }
  }

  /**
   * scroll to top when target is dashboard element
   */
  scrollToTop() {
    const content = document.querySelector('content') as HTMLElement;
    if (content != null) {
      setTimeout(() => {
        content.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }, 1);
    }
  }

}
export interface FileManagerAccount {
  file_manager: string,
  account_name: string
}
