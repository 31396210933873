import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textTrim'
})
export class TextTrimPipe implements PipeTransform {

  /**
   * Trim a text
   * @param value : any
   * @returns {any}
   */
  transform(value: any): any {
    if (value)
      return value.trim();
  }

}
