import { OverlayContainer } from '@angular/cdk/overlay';
import { AfterViewInit } from '@angular/core';
import { Directive, OnInit } from '@angular/core';
import { MatSelect } from '@angular/material';

@Directive({
  selector: '[appCustomMatSelect]'
})
export class CustomMatSelectDirective implements OnInit {

  constructor(
    private matSelect: MatSelect,
    private overlayContainer: OverlayContainer
  ) { }

  /**
   * Lifecycle hook: OnInit
   */
  ngOnInit(): void {
    // add class to overlay container
    this.overlayContainer.getContainerElement().classList.add('select-overlay');

  }



}
