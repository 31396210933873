import { Component, Inject, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MatRadioChange, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectsService } from 'app/main/services/projects.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { CommunicationService } from 'app/main/services/communication.service';


@Component({
  selector: 'app-duplicate-board',
  templateUrl: './duplicate-board.component.html',
  styleUrls: ['./duplicate-board.component.scss']
})
export class DuplicateBoardComponent implements OnInit, OnDestroy {

  frmDuplicateBoard: FormGroup;
  txtProject = new FormControl({ value: this.data.name + '--DUPLICATE', disabled: true }, [Validators.required]);
  showCurrent = true;
  errMsg = "";

  private _unsubscribe = new Subject();

  @ViewChild('mDiv') mDiv: ElementRef;
  constructor(
    public matDialogRef: MatDialogRef<DuplicateBoardComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private projectService: ProjectsService,
    private commService: CommunicationService,
  ) { }

  ngOnInit() {

    // this.frmDuplicateBoard = new FormGroup({
    //   txtProject: new FormControl({value: this.data.name+'--DUPLICATE', disabled: true}, [Validators.required]),
    // txtAddress: new FormControl(""),
    // boardType: new FormControl("current", [Validators.required])
    // });
  }

  /**
   * Lifecycle hook : OnDestroy
   */
  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  /**
   * Close this dialog box
   */
  close() {
    this.matDialogRef.close();
  }

  /**
   * Getter for form controls
   */
  get formControlProject() { return this.frmDuplicateBoard.controls; }


  /**
   * Create copy of this board
   */
  duplicateBoard() {

    // if(this.frmDuplicateBoard.valid){
    this.commService.showHideLoader('Duplicating...', true);
    let tempdata = cloneDeep(this.data);
    let tempcards = tempdata.cards.map(({ availableLabels, ...remaining }) => remaining);


    tempcards.forEach(el => {
      let att = [];
      el.attachments.forEach(obj => att.push(obj._id));
      el.attachments = att;

      let idMemberCard = [];
      el.idMembers.forEach(obj => idMemberCard.push(obj._id));
      el.idMembers = idMemberCard;

      let timeSh = [];
      el.timesheets.forEach(obj => timeSh.push(obj._id));
      el.timesheets = timeSh;


      el.comments.forEach(comment => {

        comment.idMember = comment.idMember._id;
      });

    });


    let payload2: any = {
      name: this.txtProject.value,
      labels: tempdata.labels,
      lists: tempdata.lists.map(({ availableCards, availableProjects, checkItems, checkItemsChecked, completeCount, progress, ...remaining }) => remaining),
      cards: tempcards,
      isDateChangeApproved: tempdata.isDateChangeApproved,

    }

    if (tempdata.isDateChangeApproved)
      payload2.approvalDays = tempdata.approvalDays;

    this.projectService.duplicateBoard(payload2)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(res => {

        setTimeout(() => {
          this.commService.showHideLoader('', false);
        }, 500);
        this.matDialogRef.close({ result: true });
      }, (err) => {
        this.commService.showHideLoader('', false);
        err.status == 422 ?
          this.errMsg = err.message : this.errMsg = "";

      });

  }

}
