import { Directive, ViewContainerRef, OnInit, OnDestroy, TemplateRef, Input } from '@angular/core';
import { AuthService } from '../services/auth.service'

@Directive({
    selector: '[isNotCompanyUser]'
})
export class IsCompanyUserDirective implements OnInit, OnDestroy {

    @Input() isNotCompanyUser: boolean = true;
    constructor(private authService: AuthService, private temRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {
    }

    /**
     * Lifecycle hook: OnInit
     */
    ngOnInit(): void {
        if (this.isNotCompanyUser) {
            this.authService.user
                .subscribe((user) => {
                    if (user && user.accountType == 'company') {
                        this.viewContainer.clear();
                    } else {
                        this.viewContainer.createEmbeddedView(this.temRef);
                    }
                });
        } else {
            this.viewContainer.createEmbeddedView(this.temRef);
        }

    }

    ngOnDestroy(): void {

    }
}