import { CalendarEventAction } from 'angular-calendar';
import { startOfDay, endOfDay } from 'date-fns';
import { User } from '../../../models/users';

export class CalendarEventModel {
    id: string;
    start: Date;
    end?: Date;
    title: string;
    color: {
        primary: string;
        secondary: string;
    };
    actions?: CalendarEventAction[];
    allDay?: boolean;
    cssClass?: string;
    resizable?: {
        beforeStart?: boolean;
        afterEnd?: boolean;
    };
    draggable?: boolean;
    meta?: {
        location: string,
        notes: string
    };
    members?: User[];
    creator?: {
        _id: string,
        name: string,
        img: string
    };

    /**
     * Constructor
     *
     * @param data
     */
    constructor(data?) {
        data = data || {};
        this.id = data._id;
        this.start = new Date(data.start) || startOfDay(new Date());
        this.end = new Date(data.end) || endOfDay(new Date());
        this.title = data.title || '';
        this.color = {
            // primary: data.color && data.color.primary || '#1e90ff',
            primary: data.color && data.color.primary || '#1860B4',
            // secondary: data.color && data.color.secondary || '#D1E8FF'
            secondary: data.color && data.color.secondary || '#1860B4'
        };
        this.draggable = data.draggable || true;
        this.resizable = {
            beforeStart: data.resizable && data.resizable.beforeStart || true,
            afterEnd: data.resizable && data.resizable.afterEnd || true
        };
        this.actions = data.actions || [];
        this.allDay = data.allDay || false;
        this.cssClass = data.cssClass || '';
        this.meta = {
            location: data.meta && data.meta.location || '',
            notes: data.meta && data.meta.notes || ''
        };
        this.members = data.members || [];
        this.creator = {
            _id: data.creator && data.creator._id || '',
            name: data.creator && data.creator.name || '',
            img: data.creator && data.creator.img || '',
        }
    }
}
