import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class BaseService {
    constructor(private http: HttpClient) {}
    get(
        path: any,
        isAuthorizedRequest: boolean,
        params?: HttpParams
    ): Observable<any> {
        if (isAuthorizedRequest) {
            return this.http.get(path, {
                headers: {
                    Authorization: this.tokenGenerator(),
                },
                params: params,
            });
        } else {
            return this.http.get(path);
        }
    }

    post(path: any, isAuthorizedRequest: boolean, data: any): Observable<any> {
        try {
            if (isAuthorizedRequest) {
                return this.http.post(path, data, {
                    headers: {
                        Authorization: this.tokenGenerator(),
                    },
                });
            } else {
                return this.http.post(path, data);
            }
        } catch (err) {
            
            isAuthorizedRequest;
        }
    }

    put(path: any, isAuthorizedRequest: boolean, data: any): Observable<any> {
        if (isAuthorizedRequest) {
            return this.http.put(path, data, {
                headers: {
                    Authorization: this.tokenGenerator(),
                },
            });
        } else {
            return this.http.put(path, data);
        }
    }

    patch(
        path: any,
        isAuthorizedRequest: boolean,
        data: any,
        params?: HttpParams
    ): Observable<any> {
        if (isAuthorizedRequest) {
            return this.http.patch(path, data, {
                headers: {
                    Authorization: this.tokenGenerator(),
                },
                params,
            });
        } else {
            return this.http.patch(path, data);
        }
    }

    delete(
        path: any,
        isAuthorizedRequest: boolean,
        data?: any
    ): Observable<any> {
        if (isAuthorizedRequest) {
            return this.http.request("delete", path, {
                headers: {
                    Authorization: this.tokenGenerator(),
                },
                body: data,
            });
        } else {
            return this.http.request("delete", path, {
                body: data,
            });
        }
    }

    private tokenGenerator() {
        return "Bearer " + localStorage.getItem("barereToken");
    }
}
