import { Injectable } from '@angular/core';
import { BaseService } from 'app/main/services/base.service';
import { environment } from 'environments/environment.uat';

@Injectable({
  providedIn: 'root'
})
export class StakeholderNotificationsService {

  showPanel: boolean = false;
  apiRootUrl = environment.basePath;

  constructor(
    private baseService: BaseService
  ) { }

  getNotifications(limit, skip) {
    return this.baseService.get(this.apiRootUrl + `/users/notifications?limit=${limit}&skip=${skip}`, true);
  }

  getNotificationCount() {
    return this.baseService.get(this.apiRootUrl + "/users/notifications-count", true);
  }

  markAllAsSeen(ids: any) {
    return this.baseService.patch(this.apiRootUrl + "/notifications/seen", true, ids);
  }

  deleteAll(obj: any) {
    return this.baseService.delete(this.apiRootUrl + "/notifications", true, obj);
  }

  updateNotification(id: string) {
    return this.baseService.patch(this.apiRootUrl + "/notifications/seen/" + id, true, null);
  }
}
