import { Component, Inject, OnDestroy, OnInit, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';


//fuse services
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
//subtraid services
import { OnesignalService } from './main/services/onesignal.service';

import { navigation, navStakeholder, navSubtraidEmployee } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
//sw
import { SwPush, SwUpdate } from "@angular/service-worker";
import { AuthService } from './main/services/auth.service';


@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;
    readonly VAPID_PUBLIC_KEY = "BDaLqjci3oW9DZJ3N99LlHUkFxPXBr0Zl6lHP3AgNH-0pbNFIga-aB0xK_ZvP2YV5jC1KrWbr70mbZ9OsXWrqgI";
    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private swPush: SwPush,
        private swUpdate: SwUpdate,
        private onesignalService: OnesignalService, // initialized the onesignal
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private authService: AuthService,
        private meta: Meta
    ) {

        this.authService.user.subscribe((user) => {
            // Get default navigation

            if (this.authService.isSubtraidEmployee())
                this.navigation = navSubtraidEmployee;
            else {
                //this.navigation = navigation;   //before

                //stakeholder
                if (this.authService.isStakeholderUser())
                    this.navigation = navStakeholder;
                else
                    this.navigation = navigation;
            }


            if (this._fuseNavigationService.getCurrentNavigation() == null) {
                // Register the navigation to the service            
                this._fuseNavigationService.register('main', this.navigation);

                // Set the main navigation as our current navigation
                this._fuseNavigationService.setCurrentNavigation('main');
            }
            else {
                this._fuseNavigationService.unregister('main');
                this._fuseNavigationService.register('main', this.navigation);
                this._fuseNavigationService.setCurrentNavigation('main');
            }

        });




        // Add languages
        this._translateService.addLangs(['en', 'tr']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        this._translateService.use('en');

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        // add custom icons to mat-icon registry
        this.iconRegistry.addSvgIcon('subtraid-menu', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/subtraid-menu.svg'));
        this.iconRegistry.addSvgIcon('calendar', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/calendar.svg'));
        this.iconRegistry.addSvgIcon('custom-chat', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/chat.svg'));
        this.iconRegistry.addSvgIcon('logout', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/logout.svg'));
        this.iconRegistry.addSvgIcon('project', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/project.svg'));
        this.iconRegistry.addSvgIcon('company', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/company.svg'));
        this.iconRegistry.addSvgIcon('timesheets', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/timesheets.svg'));
        this.iconRegistry.addSvgIcon('my-contacts', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/my-contacts.svg'));
        this.iconRegistry.addSvgIcon('people-group', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/people-group.svg'));
        this.iconRegistry.addSvgIcon('search', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/search.svg'));
        this.iconRegistry.addSvgIcon('delete', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/delete.svg'));
        this.iconRegistry.addSvgIcon('card-view', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/card.svg'));
        this.iconRegistry.addSvgIcon('list-view', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/list.svg'));
        this.iconRegistry.addSvgIcon('email', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/email.svg'));
        this.iconRegistry.addSvgIcon('phone', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/phone-call.svg'));
        this.iconRegistry.addSvgIcon('circle', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/filled-circle.svg'));
        this.iconRegistry.addSvgIcon('edit', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/edit.svg'));
        this.iconRegistry.addSvgIcon('chat', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/comment.svg'));
        this.iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/add.svg'));
        this.iconRegistry.addSvgIcon('add-user', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/add-person.svg'));
        this.iconRegistry.addSvgIcon('remove-user', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/remove-person.svg'));
        this.iconRegistry.addSvgIcon('bell', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/bell.svg'));
        this.iconRegistry.addSvgIcon('settings', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/settings.svg'));
        this.iconRegistry.addSvgIcon('down-arrow', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/down-arrow.svg'));
        this.iconRegistry.addSvgIcon('up-arrow', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/up-arrow.svg'));
        this.iconRegistry.addSvgIcon('down-arrow-svg', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/down-arrow-svg.svg'));
        this.iconRegistry.addSvgIcon('up-arrow-svg', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/up-arrow-svg.svg'));
        this.iconRegistry.addSvgIcon('back', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/back.svg'));
        this.iconRegistry.addSvgIcon('export', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/export.svg'));
        this.iconRegistry.addSvgIcon('location', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/location.svg'));
        this.iconRegistry.addSvgIcon('plus', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/plus.svg'));
        this.iconRegistry.addSvgIcon('sclose', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/close.svg'));
        this.iconRegistry.addSvgIcon('plus-light', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/plus-light.svg'));
        this.iconRegistry.addSvgIcon('more', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/more.svg'));
        this.iconRegistry.addSvgIcon('folder', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/folder.svg'));
        this.iconRegistry.addSvgIcon('close-dark', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/close-dark.svg'));
        this.iconRegistry.addSvgIcon('card-tag', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/card-tag.svg'));
        this.iconRegistry.addSvgIcon('attachments', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/attachments.svg'));
        this.iconRegistry.addSvgIcon('comments', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/comments.svg'));
        this.iconRegistry.addSvgIcon('arrow-down-circle', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/arrow-down-circle.svg'));
        this.iconRegistry.addSvgIcon('unchecked', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/checkbox-empty.svg'));
        this.iconRegistry.addSvgIcon('checked', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/checkbox-check.svg'));
        this.iconRegistry.addSvgIcon('issue', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/issue.svg'));
        this.iconRegistry.addSvgIcon('info', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/info.svg'));
        this.iconRegistry.addSvgIcon('tag', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/tag.svg'));
        this.iconRegistry.addSvgIcon('tag2', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/tag2.svg'));
        this.iconRegistry.addSvgIcon('expand', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/expand.svg'));
        this.iconRegistry.addSvgIcon('checked-light', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/checkbox-check-light.svg'));
        this.iconRegistry.addSvgIcon('plus-bold', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/plus-thick.svg'));
        this.iconRegistry.addSvgIcon('people-group2', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/assignee.svg'));
        this.iconRegistry.addSvgIcon('clip', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/clip.svg'));
        this.iconRegistry.addSvgIcon('xls', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/ext-xls.svg'));
        this.iconRegistry.addSvgIcon('pdf', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/ext-pdf.svg'));
        this.iconRegistry.addSvgIcon('image', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/ext-img.svg'));
        this.iconRegistry.addSvgIcon('doc', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/ext-doc.svg'));
        this.iconRegistry.addSvgIcon('general', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/ext-general.svg'));
        this.iconRegistry.addSvgIcon('comments2', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/comment-card.svg'));
        this.iconRegistry.addSvgIcon('send', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/paper-plane.svg'));
        this.iconRegistry.addSvgIcon('duplicate', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/duplicate.svg'));
        this.iconRegistry.addSvgIcon('template', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/template.svg'));
        this.iconRegistry.addSvgIcon('location2', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/marker.svg'));
        this.iconRegistry.addSvgIcon('stopwatch', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/stopwatch.svg'));
        this.iconRegistry.addSvgIcon('close-large-svg', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/close-large-svg.svg'));
        this.iconRegistry.addSvgIcon('subtraid-logo', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/logo/Subtraid_Icon_Blue@16x.svg'));
        this.iconRegistry.addSvgIcon('left-arrow', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/left-arrow.svg'));
        this.iconRegistry.addSvgIcon('right-arrow', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/right-arrow.svg'));
        this.iconRegistry.addSvgIcon('sunny', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/sunny.svg'));
        this.iconRegistry.addSvgIcon('humidity', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/humidity.svg'));
        this.iconRegistry.addSvgIcon('wind', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/wind.svg'));
        this.iconRegistry.addSvgIcon('weather-loc', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/weather-loc.svg'));
        this.iconRegistry.addSvgIcon('degree', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/degree.svg'));
        this.iconRegistry.addSvgIcon('attach-pic', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/attach-pic.svg'));
        this.iconRegistry.addSvgIcon('send-fill', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/paper-plane-fill.svg'));
        this.iconRegistry.addSvgIcon('download', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/download.svg'));
        this.iconRegistry.addSvgIcon('chat-search', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/chat-search.svg'));
        this.iconRegistry.addSvgIcon('bug', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/bug.svg'));
        this.iconRegistry.addSvgIcon('plus-svg', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/plus-svg.svg'));
        this.iconRegistry.addSvgIcon('user-active', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/user-active.svg'));
        this.iconRegistry.addSvgIcon('edit-admin', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/edit-admin.svg'));
        this.iconRegistry.addSvgIcon('users', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/users.svg'));
        this.iconRegistry.addSvgIcon('user-deactive', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/user-deactive.svg'));
        this.iconRegistry.addSvgIcon('file', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/file.svg'));
        this.iconRegistry.addSvgIcon('one-drive', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/one-drive.svg'));
        this.iconRegistry.addSvgIcon('dropbox', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/dropbox.svg'));
        this.iconRegistry.addSvgIcon('gdrive', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/gdrive.svg'));
        this.iconRegistry.addSvgIcon('od-image', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/od-image.svg'));
        this.iconRegistry.addSvgIcon('od-files', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/od-files.svg'));
        this.iconRegistry.addSvgIcon('od-folder', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/od-folder.svg'));
        this.iconRegistry.addSvgIcon('od-music', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/od-music.svg'));
        this.iconRegistry.addSvgIcon('folder-fill', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/folder-fill.svg'));
        this.iconRegistry.addSvgIcon('more-svg', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/more-svg.svg'));
        this.iconRegistry.addSvgIcon('arrow-sort', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/arrow-sort.svg'));
        this.iconRegistry.addSvgIcon('card-svg', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/card-svg.svg'));
        this.iconRegistry.addSvgIcon('list-svg', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/list-svg.svg'));
        this.iconRegistry.addSvgIcon('active-star', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/active-star.svg'));
        this.iconRegistry.addSvgIcon('inactive-star', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/inactive-star.svg'));
        this.iconRegistry.addSvgIcon('db-pdf', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/db-pdf.svg'));
        this.iconRegistry.addSvgIcon('db-ppt', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/db-ppt.svg'));
        this.iconRegistry.addSvgIcon('db-sheet', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/db-sheet.svg'));
        this.iconRegistry.addSvgIcon('db-video', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/db-video.svg'));
        this.iconRegistry.addSvgIcon('db-other', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/db-other.svg'));
        this.iconRegistry.addSvgIcon('gd-archive', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/gd-archive.svg'));
        this.iconRegistry.addSvgIcon('gd-audio', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/gd-audio.svg'));
        this.iconRegistry.addSvgIcon('gd-doc', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/gd-doc.svg'));
        this.iconRegistry.addSvgIcon('gd-drawing', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/gd-drawing.svg'));
        this.iconRegistry.addSvgIcon('gd-form', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/gd-form.svg'));
        this.iconRegistry.addSvgIcon('gd-mydrive', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/gd-mydrive.svg'));
        this.iconRegistry.addSvgIcon('gd-pdf', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/gd-pdf.svg'));
        this.iconRegistry.addSvgIcon('gd-pic', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/gd-pic.svg'));
        this.iconRegistry.addSvgIcon('gd-ppt', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/gd-ppt.svg'));
        this.iconRegistry.addSvgIcon('gd-recent', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/gd-recent.svg'));
        this.iconRegistry.addSvgIcon('gd-shared', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/gd-shared.svg'));
        this.iconRegistry.addSvgIcon('gd-sheet', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/gd-sheet.svg'));
        this.iconRegistry.addSvgIcon('gd-shortcut', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/gd-shortcut.svg'));
        this.iconRegistry.addSvgIcon('gd-sites', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/gd-sites.svg'));
        this.iconRegistry.addSvgIcon('gd-video', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/gd-video.svg'));
        this.iconRegistry.addSvgIcon('eye', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/eye.svg'));
        this.iconRegistry.addSvgIcon('link', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/link.svg'));
        this.iconRegistry.addSvgIcon('csv', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/csv.svg'));
        this.iconRegistry.addSvgIcon('network', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/network.svg'));
        this.iconRegistry.addSvgIcon('basic-setting', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/basic-setting7.svg'));
        this.iconRegistry.addSvgIcon('pending', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/pending.svg'));
        this.iconRegistry.addSvgIcon('check-circle-filled', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/check-circle-filled.svg'));
        this.iconRegistry.addSvgIcon('cancel-filled', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/cancel-filled.svg'));
        this.iconRegistry.addSvgIcon('exit', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/exit.svg'));
        this.iconRegistry.addSvgIcon('handshake', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/handshake.svg'));
        this.iconRegistry.addSvgIcon('gantt', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/gantt1.svg'));
        this.iconRegistry.addSvgIcon('restore', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/restore_from_trash.svg'));
        this.iconRegistry.addSvgIcon('branch', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/branch.svg'));
        this.iconRegistry.addSvgIcon('download2', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/download2.svg'));
        this.iconRegistry.addSvgIcon('hashtag', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/hashtag.svg'));
        this.iconRegistry.addSvgIcon('view-more', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/read_more.svg'));
        this.iconRegistry.addSvgIcon('reply-msg', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/reply.svg'));
        this.iconRegistry.addSvgIcon('move-up', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/move_up.svg'));
        this.iconRegistry.addSvgIcon('quick-reference', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/quick_reference.svg'));
        this.iconRegistry.addSvgIcon('transfer', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/transfer.svg'));
        this.iconRegistry.addSvgIcon('play', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/play.svg'));
        this.iconRegistry.addSvgIcon('dwg', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/dwg.svg'));
        this.iconRegistry.addSvgIcon('dxf', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/dxf.svg'));
        this.iconRegistry.addSvgIcon('user-gear', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/user-gear.svg'));
        this.iconRegistry.addSvgIcon('upcoming', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/upcoming.svg'));
        this.iconRegistry.addSvgIcon('dashboard', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/dashboard.svg'));
        this.iconRegistry.addSvgIcon('updates', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/updates.svg'));
        this.iconRegistry.addSvgIcon('upcoming2', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/upcoming2.svg'));
        this.iconRegistry.addSvgIcon('pcr', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/pcr.svg'));
        this.iconRegistry.addSvgIcon('rfi', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/rfi.svg'));
        this.iconRegistry.addSvgIcon('submittals', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/submittals.svg'));
        this.iconRegistry.addSvgIcon('finance', sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/custom-icons2/finance.svg'));
    }


    /**
     * On window resize
     * @param event : any
     */
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        //set viewport
        this.resizeWindow();

    }

    /**
     * On window load
     */
    @HostListener('window:load')
    onLoad() {
        this.resizeWindow();
    }

    /**
     * Set view port based on screen size
     */
    resizeWindow() {
        const viewport = this.meta.getTag('name=viewport');

        if (viewport)
            this.meta.removeTag('name=viewport');
        if (window.screen.width <= 599)
            this.meta.addTag({ name: 'viewport', content: 'width=599' });
        else
            this.meta.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' });
    }
    /**
     * On init
     */
    ngOnInit(): void {

        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {


                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                }
                else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });

        // Service worker update *angular sw
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                window.location.reload();
            });
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}
