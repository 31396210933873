import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MediastorageService {

  private medias = {
    id: String,
    url: String
  }

  constructor() {
  }


/**
 * Get media
 *
 * @param String : mId
 * @return String : mediaUrl
 */

  getByID(mId){

    if(this.medias[mId]){
      return this.medias[mId]
    }

    return null
  }

  /**
  *
  * @param String : mId
  * @param String : url
  */

  add(mId , url){

    // make sure the mId is not empty
    if(mId === undefined) return

    this.medias[mId] = url;
  }

}
