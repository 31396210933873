import { Injectable , OnInit } from '@angular/core';
import { AuthService } from './auth.service'

@Injectable({
  providedIn: 'root'
})
export class OnesignalService implements OnInit {


  constructor(
    private authService: AuthService
  ) {
  }

  /**
   * On init
   */
  ngOnInit(): void
  {

          var OneSignal = window['OneSignal'] || [];

          OneSignal.push(function() {
            OneSignal.isPushNotificationsEnabled(function(isEnabled) {

              

              if (isEnabled) {
                //if is login
                if(this.authService.isLogin()){
                  let loginUserObj = this.authService.getBarereToken();
                  let userName = loginUserObj.name;
                  let userId = loginUserObj._id;
                  this.addTags({
                    name:userName,
                    _id:userId,
                    active: true
                  });

                }
              }
            });

            // Occurs when the user's subscription changes to a new value.
            OneSignal.on('subscriptionChange', function (isSubscribed) {

              // console.log("One OneSignal subscription changes");
              // console.log("The user's subscription state is now:", isSubscribed);

              if(isSubscribed){

                //if is login
                if(this.authService.isLogin()){
                  let loginUserObj = this.authService.getBarereToken();
                  let userName = loginUserObj.name;
                  let userId = loginUserObj._id;
                  this.addTags({
                    name:userName,
                    _id:userId,
                    active: true
                  });

                }

              }else{
                // console.log('Setting Onesignal Backend notification subscription to false')
                OneSignal.setSubscription(false).then(
                  (result) => {
                     
                    //  console.log('Onesignal Backend notification subscription is set to false')
                  },
                  (err) => {
                    console.log(err)
                  }
                );
              }
            });

          });
  }

  clearTag(){

    var OneSignal = window['OneSignal'] || [];

    OneSignal.push(function() {
      OneSignal.deleteTags(["name", "_id" , "active"]);
    });
  }


  removeActiveTag(){

    var OneSignal = window['OneSignal'] || [];

    OneSignal.push(function() {
      OneSignal.deleteTags(["active"]);
    });
  }

  /*
    @Param tags : key value pair object
  */

  addTags(tags){

    var OneSignal = window['OneSignal'] || [];

    OneSignal.push(function() {
      OneSignal.sendTags(tags).then(function(tagsSent) {
        console.log('One signal tags has been successfully added',tagsSent);
      });
    });
  }

}
