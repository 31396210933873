import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.uat';

@Injectable()

export class RequestService {

  basePath = environment.basePath;
  constructor(
    private _httpClient : HttpClient
  ) { }

  /**
 * @function
 *    Create new friend request
 * @param
 *   sender : User Object
 * @param
 *   reciver : User Object
 * @return
 *    Observable
 */

  makeNewFriendRequest(sender, reciver){

    const requestEndpoint : string = this.basePath + '/requests';

    let senderName = sender.name;
    let receiverName = reciver.name;
    let senderId = sender._id;
    let receiverId = reciver._id;

    const payload = {
        "reqType": "friend",
        "receiverId": receiverId,
        "message": `Friend request from ${senderName}(${senderId}) to ${receiverName}(${receiverId})`
    }

    return this._httpClient.post(requestEndpoint, payload)

  }

  /**
 * @function
 *    delete a reqeust by id
 * @param
 *   requestId : string
 * @return
 *    Observable
 */


  deleteRequestById(requestId){

    const deleteRequestEndpoint : string = this.basePath + '/requests/' + requestId;

    return this._httpClient.delete(deleteRequestEndpoint)

  }

  /**
 * @function
 *    Accpet a friend requect
 * @param
 *   requestId : string
 * @return
 *    Observable
 */


  acceptFriendRequest(requestId){

    const deleteRequestEndpoint : string = this.basePath + '/requests/acceptfriend/' + requestId;

    return this._httpClient.delete(deleteRequestEndpoint)

  }

}
