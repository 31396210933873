import { Injectable } from "@angular/core";
import { environment } from "environments/environment.uat";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Card } from "../pages/dashboard/scrumboard/card";
import { ProjectPermissionSettings } from "../pages/dashboard/scrumboard/permissions";
import { Project } from "../pages/dashboard/scrumboard/project.model";
import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";
import { takeUntil } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class ProjectsService {
    apiUrl = environment.basePath + "/projects";
    userProjectApiUrl = environment.basePath + "/users/projects";
    userTemplatesApiUrl = environment.basePath + "/templates";
    timesheetApiUrl = environment.basePath + "/timesheets";
    issueApiUrl = environment.basePath + "/projectIssues";

    private onProjectChange = new BehaviorSubject<Project | null>(null);
    public projectBoard = this.onProjectChange.asObservable();

    private onCardDetailUpdate = new BehaviorSubject<Card | null>(null);
    public projectCard = this.onCardDetailUpdate.asObservable();

    private onProjectSettingChange = new BehaviorSubject<ProjectPermissionSettings | null>(null);
    public projectSetting = this.onProjectSettingChange.asObservable();

    private onWeatherLocationChange = new BehaviorSubject(null);
    public weatherLocation = this.onWeatherLocationChange.asObservable();

    private onCollabProjChange = new BehaviorSubject(null);
    public collabProjects = this.onCollabProjChange.asObservable();

    private onProjDetailTourStart = new BehaviorSubject(null);
    public projDetailTourStarted = this.onProjDetailTourStart.asObservable();

    private onTargetDime = new BehaviorSubject(null);
    public targetDimension = this.onTargetDime.asObservable();

    pageSize: number = 30;
    height$ = new Subject<number>();
    private _unsubscribe = new Subject();

    stakeHolderRoles = ["Architect", "Engineer", "Architect / Engineer", "Owner", "Owner Representative"];

    /**
     * Height of Card Comments element increases when user make entry in input box
     * It is used in adjusting height of Comment's parallel elements- File Attachments & Assigness - in Card Detail page
     * @param height 
     */
    setHeight(height: number) {
        this.height$.next(height);
    }

    // getAll() {
    //     return this.baseCaller.get(this.apiUrl, true);
    //     // return this.baseCaller.get(environment.basePath+"/users/projects-list", true);
    // }

    /**
     * Create project
     * @param project : Payload object
     * @returns {Observable<any>}
     */
    create(project) {
        return this.baseCaller.post(this.apiUrl, true, project);
    }

    /**
     * Get a Project detail
     * @param byId : string - Project id
     * @param msg : string 
     * @returns {Observable<any>}
     */
    getProject(byId, msg) {
        return this.baseCaller.get(this.apiUrl + "/" + byId, true);
    }

    /**
     * Delete a project
     * @param id : string - project id
     * @returns {Observable<any>}
     */
    deleteProject(id) {
        return this.baseCaller.delete(this.apiUrl + "/" + id, true, id);
    }

    // getProjectsForUser() {
    //     return this.baseCaller.get(this.userProjectApiUrl + "/", true);
    // }


    // getProjectsOfEmployer() {
    //     return this.baseCaller.get(this.apiUrl + "/employer", true);
    // }
    constructor(
        private baseCaller: BaseService,
        private _httpClient: HttpClient,

    ) { }



    //a5e

    // getUsersProject(sortByName: boolean) {
    //     let api = "/users/projects-list";
    //     if (sortByName)
    //         api = api + "?sort=name";
    //     return this.baseCaller.get(environment.basePath + api, true);
    // }

    /**
     * Get projects list
     * @param sortByName  
     * @param pageNo : current page number
     * @param searchString : string - project name to search
     * @returns {Observable<any>}
     */
    getAllProjects(sortByName: boolean, pageNo: number, searchString) {
        let api = "/users/projects-list?";
        if (sortByName)
            api = api + "sort=name&";
        return this.baseCaller.get(environment.basePath + api + `pageNumber=${pageNo}&pageSize=${this.pageSize}&name=${searchString}`, true,);
    }

    /**
     * Get saved board templates list
     * @returns {Observable<any>}
     */
    getTemplates() {
        return this.baseCaller.get(this.userTemplatesApiUrl, true);
    }

    /**
     * Delete a template
     * @param id : string - template id
     * @returns {Observable<any>}
     */
    deleteTemplate(id) {
        return this.baseCaller.delete(this.userTemplatesApiUrl + "/" + id, true);
    }

    /**
     * Create a template
     * @param data : payload object
     * @returns {Observable<any>}
     */
    createTemplate(data) {
        return this.baseCaller.post(this.userTemplatesApiUrl, true, data);
    }

    /**
     * Add list to project's board
     * @param data : payload object
     * @returns {Observable<any>}
     */
    addList(data) {
        return this.baseCaller.patch(this.apiUrl + '/list/add', true, data);
    }

    /**
     * Refresh project data
     * @param res : projects response
     */
    refreshProject(res) {

        // if (res == null) {
        //     this.onProjectChange.next(null);
        //     this.onProjectSettingChange.next(null);
        //     this.onCollabProjChange.next(null);
        // }
        // else {
        let proj: Project;


        proj = {
            _id: res._id,
            lists: res.lists,
            author: res.author,
            owner: res.owner,
            dateCreated: res.dateCreated,
            startDate: res.startDate,
            formattedAddress: res.formattedAddress,
            location: res.location ? res.location : null,
            subprojects: res.subprojects,
            timesheets: res.timesheets,
            status: res.status,
            idMembers: res.idMembers,
            managers: res.managers,
            name: res.name,
            labels: res.labels,
            settings: res.settings,
            cards: res.cards,
            conversation: res.conversation ? res.conversation : '',
            collaborators: res.collaborators,
            loginUserProjectPermission: res.loginUserProjectPermission,
            idCrews: res.idCrews,
            isCollaborated: res.isCollaborated,
            permissions: res.permissions,
            isDateChangeApproved: res.isDateChangeApproved ? res.isDateChangeApproved : false,            // miscPermission: miscPermission
            endDate: res.endDate,
            isUpdateRequestPending: res.isUpdateRequestPending,
            GroupedIdMembers: res.GroupedIdMembers,
            SubGroupsIdMembers: res.SubGroupsIdMembers,
            finances: res.finances,
            stakeHolder: res.stakeHolder,
            projectUpdates: res.projectUpdates,
            updateWeeks: res.updateWeeks ? res.updateWeeks.toString() : ""
        }
        if (res.parent) proj.parentProject = res.parent;
        if (res.value) proj.projectValue = res.value;
        if (proj.isDateChangeApproved)
            proj.approvalDays = res.approvalDays;


        proj.lists.forEach(list => {
            list.availableCards = [];
            proj.cards.forEach(card => {
                if (card.listId == list._id) {
                    list.availableCards.push(card);
                }
            });

        });

        // find collaborated card, so that it can be used to hide delete card button
        let collabcard = proj.cards.find(obj => obj.parentCard != undefined);
        // mark collaborated list, so that it can be used to hide delete list button
        if (collabcard != undefined) proj.lists.find(obj => obj._id == collabcard.listId).collabList = true;

        proj.lists.forEach(list => {
            // list.availableLabels=[];
            list.availableCards.forEach(card => {
                card.availableLabels = [];
                card.idLabels.forEach(id => {
                    proj.labels.forEach(label => {
                        if (label._id == id)
                            card.availableLabels.push(label);
                    });
                });

            });

        });

        proj.lists.forEach((list) => {
            list.availableProjects = [];
            // list.idProjects.forEach(obj => {
            proj.subprojects.forEach(sub => {
                if (sub.parentCard.length > 0 && sub.parentCard[0].listId == list._id)
                    list.availableProjects.push(sub);
            });
            // });
        });

        proj.lists.sort((a, b) => a.position > b.position ? 1 : -1);

        this.onProjectChange.next(proj);

        let setting: ProjectPermissionSettings = {
            projectId: proj._id,
            loginUserProjectPermission: proj.loginUserProjectPermission,
            settings: proj.settings,
            isCollaboratedProject: proj.isCollaborated,
            status: proj.status
        };
        this.onProjectSettingChange.next(setting);


        //colaborated projects
        let collab = [];
        proj.collaborators.forEach((el) => {
            var cp = proj.subprojects.filter((obj) => {
                if (obj.owner._id == el._id)
                    return obj;
            });
            if (typeof cp !== 'undefined' && cp.length > 0)
                cp.forEach(data => collab.push(data));

        });

        this.onCollabProjChange.next(collab);
        // }
    }

    /**
     * Remove a list from project
     * @param data : payload object
     * @returns {Observable<any>}
     */
    removeList(data) {
        return this.baseCaller.patch(this.apiUrl + '/list/remove', true, data);
    }

    /**
     * Add a card in project
     * @param data : payload object
     * @returns {Observable<any>}
     */
    addCard(data) {

        return this.baseCaller.patch(this.apiUrl + '/card/add', true, data);
    }

    /**
     * Add a sub-project in a project
     * @param data : payload object
     * @returns {Observable<any>}
     */
    addSubProject(data) {
        return this.baseCaller.patch(this.apiUrl + '/subproject/add', true, data);
    }

    /**
     * Move card to another list
     * @param data : payload object
     * @returns 
     */
    shiftCard(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/shiftcard', true, data);
    }

    // shiftSubProject(data) {
    //     return this.baseCaller.patch(this.apiUrl + '/subproject/shift', true, data);
    // }


    // changeListPosition(data) {
    //     return this.baseCaller.patch(this.apiUrl + '/list/reposition', true, data);
    // }

    // updateListName(data) {
    //     return this.baseCaller.patch(this.apiUrl + '/list/updatename', true, data);
    // }

    /**
     * Search in a project
     * @param data : payload object
     * @param id : string - project id
     * @returns {Observable<any>}
     */
    searchInProjectByID(data, id) {
        return this.baseCaller.get(this.apiUrl + '/search/' + id, true, data);
    }

    /**
     * Get a card detail
     * @param id : string - card id
     * @param data : payload object
     * @returns {Observable<any>}
     */
    getCardDetailByID(id, data) {
        return this.baseCaller.get(this.apiUrl + '/card/' + id, true, data);
    }

    /**
     * Update a card info
     * @param data : payload object
     * @returns {Observable<any>}
     */
    updateCardStatic(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/update_card_static', true, data);
    }

    /**
     * Delete a card
     * @param data : payload object
     * @returns {Observable<any>}
     */
    deleteCard(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/remove', true, data);
    }

    /**
     * Update a card status
     * @param data : payload object
     * @returns {Observable<any>}
     */
    updateCardStatus(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/status', true, data);
    }

    /**
     * Create task for a card
     * @param data : payload object
     * @returns {Observable<any>}
     */
    createSubTask(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/add/checklist', true, data);
    }

    /**
     * Create a checklist for a card's task
     * @param data : payload object
     * @returns {Observable<any>}
     */
    createCheckListItem(data) {
        //create sub-task
        return this.baseCaller.patch(this.apiUrl + '/card/add/checkitem', true, data);
    }

    /**
     * Remove a task from card
     * @param data : payload object
     * @returns {Observable<any>}
     */
    removeSubTask(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/remove/checklist', true, data);
    }

    /**
     * Update a card's task info
     * @param data : payload object
     * @returns {Observable<any>}
     */
    updateSubTask(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/update/checkList', true, data);
    }

    /**
     * Update a checklist info of card's task
     * @param data : payload object
     * @returns {Observable<any>}
     */
    updateCheckList(data) {
        //sub-task
        return this.baseCaller.patch(this.apiUrl + '/card/update/checkItem', true, data);
    }

    /**
     * Modifies a card data
     * @param res : card info object
     * @param projectId : string - project id
     */
    modifyCardData(res: Card, projectId) {
        this.onCardDetailUpdate.next(res);
    }

    /**
     * Update check/uncheck value of tasks and checklists of a card
     * @param data : payload object
     * @returns {Observable<any>}
     */
    updateCheckListItem(data) {
        //subtask check-uncheck
        return this.baseCaller.patch(this.apiUrl + '/card/updateListChecked', true, data);
    }

    /**
     * Add card comments
     * @param data : payload object
     * @returns {Observable<any>}
     */
    addComment(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/add/comment', true, data);
    }

    /**
     * Remove card's attachment
     * @param data : payload object
     * @returns {Observable<any>}
     */
    removeAttachment(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/attachment/delete', true, data);
    }

    /**
     * Add card attachment
     * @param data : payload object
     * @returns {Observable<any>}
     */
    addAttachment(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/attachment/add', true, data);
    }

    /**
     * Get Card's members aggregated timesheet
     * @param data : payload object
     * @param cardid : string - card id
     * @returns {Observable<any>}
     */
    getCardAggregatedTimeSheet(data, cardid) {
        return this.baseCaller.post(environment.basePath + '/timesheets/filter/cards/aggregate-time/' + cardid, true, data);
    }

    /**
     * Start a card's member timesheet
     * @param data : payload object
     * @returns 
     */
    startCardTimeSheet(data) {
        // const endPoint: string = this.basePath + '/timesheets/card';
        return this.baseCaller.post(environment.basePath + '/timesheets/card', true, data);
    }

    /**
     * Stop a card's member timesheet
     * @param timesheetid : string - timesheet id
     * @param data : payload object
     * @returns {Observable<any>}
     */
    stopTimeSheet(timesheetid, data) {
        return this.baseCaller.patch(environment.basePath + '/timesheets/' + timesheetid, true, data);
    }

    /**
     * Stop multiple card members timesheet
     * @param data : payload object
     * @returns {Observable<any>}
     */
    stopMultipleTimeSheet(data) {
        return this.baseCaller.patch(environment.basePath + '/timesheets/timesheets-update', true, data);
    }

    /**
     * Add members(employees) to a card
     * @param data : payload object
     * @returns {Observable<any>}
     */
    addEmployeeToCard(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/addemployee', true, data);
    }

    /**
     * Remove a member from card
     * @param data : payload object
     * @returns {Observable<any>}
     */
    removeMemberFromCard(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/removeemployee', true, data);
    }

    /**
     * Remove a crew from card
     * @param data : payload object
     * @returns {Observable<any>}
     */
    removeCrewFromCard(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/removecrew', true, data);
    }

    /**
     * Update card's label
     * @param data : payload object
     * @returns {Observable<any>}
     */
    updateProjectLabel(data) {
        return this.baseCaller.patch(this.apiUrl + '/label/update', true, data);
    }

    /**
     * Add label to a card
     * @param data : payload object
     * @returns {Observable<any>}
     */
    addLabelToProject(data) {
        return this.baseCaller.patch(this.apiUrl + '/label/add', true, data);
    }

    /**
     * Update a project's info
     * @param data : payload object
     * @param id : string - project id
     * @returns {Observable<any>}
     */
    updateProjectInfo(data, id) {
        return this.baseCaller.patch(this.apiUrl + '/' + id, true, data);
    }

    /**
     * Start a project's member timesheet
     * @param data : payload object
     * @returns {Observable<any>}
     */
    startProjectTimeSheet(data) {
        return this.baseCaller.post(environment.basePath + '/timesheets/project', true, data);
    }

    /**
     * Get sub-projects & cards list
     * @param id : string - project id 
     * @returns {Observable<any>}
     */
    getSubprojectsCardsForIssues(id) {
        return this.baseCaller.get(this.apiUrl + '/subproject-card-detail/' + id, true);
    }

    /**
     * Add an issue in project
     * @param data : payload object
     * @returns {Observable<any>}
     */
    addIssue(data) {
        return this.baseCaller.post(this.issueApiUrl + '/create', true, data);
    }

    /**
     * Get issues list
     * @param data : payload object
     * @param id : string - project id
     * @returns {Observable<any>}
     */
    getIssueList(data, id) {
        return this.baseCaller.get(this.issueApiUrl + '/filter/' + id, true, data);
    }

    /**
     * Update an issue info
     * @param data : payload object
     * @param id : string - issue id
     * @returns {Observable<any>}
     */
    updateIssue(data, id) {
        return this.baseCaller.patch(this.issueApiUrl + '/' + id, true, data);
    }

    /**
     * Delete an issue
     * @param id : string - issue id
     * @param data : payload object
     * @returns {Observable<any>}
     */
    deleteIssue(id, data) {
        return this.baseCaller.delete(this.issueApiUrl + '/' + id, true, data);
    }

    /**
     * Add comments in an issue
     * @param data : payload object
     * @param id : string - issue id
     * @returns {Observable<any>}
     */
    addIssueComment(data, id) {
        return this.baseCaller.patch(this.issueApiUrl + '/add-comment/' + id, true, data);
    }

    /**
     * Duplicate a board template
     * @param data : payload object
     * @returns {Observable<any>}
     */
    duplicateBoard(data) {
        return this.baseCaller.post(this.apiUrl + '/duplicate', true, data);
    }

    /**
     * Get Permissions list created by root user
     * @returns {Observable<any>}
     */
    getPermissionSettings() {
        //for company user only
        return this.baseCaller.get(environment.basePath + '/users/employer/ppg', true);
    }

    /**
     * Create project permission role
     * @param data : payload object
     * @returns {Observable<any>}
     */
    createUserRole(data) {
        return this.baseCaller.patch(environment.basePath + '/companies/project_group_permission/create', true, data);
    }

    /**
     * Update project permission role
     * @param data : payload object
     * @returns {Observable<any>}
     */
    updateUserRole(data) {
        return this.baseCaller.patch(environment.basePath + '/companies/project_group_permission/update', true, data);
    }

    /**
     * Delete project permission role
     * @param id : string - role id
     * @returns {Observable<any>}
     */
    deleteUserRole(id) {
        return this.baseCaller.delete(environment.basePath + '/companies/project_group_permission/' + id, true);
    }

    /**
     * Get logged-in user's project permissions 
     * @param id : string - project id
     * @returns {Observable<any>}
     */
    getProjectPermissionSettings(id) {
        return this.baseCaller.get(this.apiUrl + '/permissions-settings/' + id, true);
    }

    /**
     * Update project's timesheet setting: visibilty in card or project or both
     * @param id : string - project id
     * @param data : payload object
     * @returns {Observable<any>}
     */
    updateProjectTimesheetSetting(id, data) {
        return this.baseCaller.patch(this.apiUrl + '/settings/' + id, true, data);
    }

    /**
     * Modify value of BehaviourSubject of logged-in user's project permissions
     * @param res : ProjectPermissionSettings
     */
    refreshProjectSetting(res: ProjectPermissionSettings) {
        this.onProjectSettingChange.next(res);
    }

    /**
     * Get weather info of a location
     * @param lng : number - langitude
     * @param lat : number - latitude
     * @returns {Observable<any>}
     */
    getWeather(lng, lat) {
        const endPoint: string = environment.basePath + `/weather?lng=${lng}&lat=${lat}`;
        return this.baseCaller.get(endPoint, true);
    }

    /**
     * Get weather forecast info for 5 days
     * @param lng : number - langitude
     * @param lat : number - latitude
     * @returns {Observable<any>}
     */
    getWeatherForecast(lng, lat) {
        return this.baseCaller.get(environment.basePath + `/weather/forecast?lng=${lng}&lat=${lat}`, true);
    }

    /**
     * Get weather info based on location's latitude and longitude
     * Update value of BehaviorSubject of weather
     * @param loc : location object
     */
    updateWeatherLoc(loc: any) {

        if (loc) {
            if (loc.lat != null && loc.lng != null) {
                this.getWeather(loc.lng, loc.lat)
                    .pipe(takeUntil(this._unsubscribe))
                    .subscribe(resp => {
                        this.onWeatherLocationChange.next(resp.weather);
                    });
            }
            else
                this.onWeatherLocationChange.next(null);
        }
        else
            this.onWeatherLocationChange.next(null);

    }

    /**
     * Get non-dependent cards list of a project
     * @param projectid : string - project id
     * @param cardid : string - card id to exclude
     * @param pageNo : number - current page number
     * @param getAll : boolean - get all cards or exclude given card id from all
     * @returns {Observable<any>}
     */
    getNonDependentCards(projectid, cardid, pageNo, getAll: boolean) {
        if (getAll)
            return this.baseCaller.get(this.apiUrl + `/${projectid}/cards?pageNumber=${pageNo}&pageSize=10`, true);
        else
            return this.baseCaller.get(this.apiUrl + `/${projectid}/cards?cardId=${cardid}&pageNumber=${pageNo}&pageSize=10`, true);
    }

    /**
     * Create card dependency on another card
     * @param data : payload object
     * @returns {Observable<any>}
     */
    createCardDepedency(data) {
        return this.baseCaller.patch(this.apiUrl + '/cards/dependent-task', true, data);
    }

    /**
     * Get dependent cards list of a card
     * @param cardid : string - card id
     * @returns {Observable<any>}
     */
    getDependentCards(cardid) {
        return this.baseCaller.get(this.apiUrl + "/cards/" + cardid + "/dependent-task", true);
    }

    /**
     * Update dependency note of a dependent card
     * @param data : payload object
     * @returns {Observable<any>}
     */
    updateDepedencyNotes(data) {
        return this.baseCaller.patch(this.apiUrl + "/cards/dependent-task/notes", true, data);
    }

    /**
     * Delete a card dependency
     * @param cardid : string - card id
     * @param dependentOnid : string - dependency id
     * @returns {Observable<any>}
     */
    deleteDepedency(cardid, dependentOnid) {
        return this.baseCaller.patch(this.apiUrl + "/cards/" + cardid + "/dependent-task/" + dependentOnid, true, dependentOnid);
    }

    // getProjectCards(projectid, cardid) {
    //     return this.baseCaller.get(`${this.apiUrl}/${projectid}/lists?cardId=${cardid}`, true);
    // }

    /**
     * Modify value of BehaviourSubject to start Project's 'Take a Tour' feature
     * @param data : boolean
     */
    startProjectDetailTour(data: boolean) {
        this.onProjDetailTourStart.next(data);
    }

    /**
     * Use to set dimension of elements for 'Take a Tour' feature of Raise an Issue page
     * @param data : object
     */
    updateTargetDimension(data) {
        this.onTargetDime.next(data);
    }

    /**
     * Update an employee's project member role
     * @param data : payload object
     * @returns {Observable<any>}
     */
    updateMemberRole(data) {
        return this.baseCaller.patch(this.apiUrl + '/permission/update', true, data);
    }

    /**
     * Raise an issue for card's start and end date change
     * @param data : payload object
     * @returns {Observable<any>}
     */
    raiseIssueForDateChange(data: any) {
        return this.baseCaller.patch(this.apiUrl + '/card/propose_card_dates', true, data);
    }

    /**
     * Get detail of a date change(card) issue
     * @param issueid : string - issue id
     * @param projectid : string -project id
     * @returns {Observable<any>}
     */
    getDateChangeIssueById(issueid: string, projectid: string) {
        return this.baseCaller.get(this.issueApiUrl + '/date-change-issue/' + issueid + '?projectId=' + projectid, true);
    }

    /**
     * Save action taken by user on a date change(card) issue
     * @param projectid : string - project id
     * @param data : payload object 
     * @returns {Observable<any>}
     */
    saveDateChangeIssueAction(projectid: string, data: any) {
        return this.baseCaller.patch(this.issueApiUrl + '/date-change-issue/update-status?projectId=' + projectid, true, data);
    }

    /**
     * Get pending date change(card) issues list 
     * @returns {Observable<any>}
     */
    getPendingIssues() {
        return this.baseCaller.get(environment.basePath + '/users/project-issues/date-change-pending-issues', true);
    }

    /**
     * Get archived projects list
     * @param data : payload object
     * @param pageNo : number - current page number
     * @returns {Observable<any>}
     */
    getAllArchivedProjects(data, pageNo) {
        return this.baseCaller.post(this.apiUrl + `/archive/projects/all?pageNumber=${pageNo}&pageSize=${this.pageSize}`, true, data);
    }

    /**
     * Restore an archived project
     * @param projectid : string - project id
     * @returns {Observable<any>}
     */
    restoreProject(projectid) {
        return this.baseCaller.post(this.apiUrl + '/archive/restore/' + projectid, true, null);
    }

    /**
     * Get requests list of sub-project's changed info from Trade Partner
     * @returns {Observable<any>}
     */
    getSubProjectUpdateRequest() {
        return this.baseCaller.get(environment.basePath + "/users/requests?type=projectUpdate", true);
    }

    /**
     * Update Sub-project's change request status
     * @param accept : boolean 
     * @param id : string - request id
     * @returns {Observable<any>}
     */
    processInfoCR(accept, id) {
        return this.baseCaller.post(environment.basePath + '/users/accept-projectUpdate?requestId=' + id + '&accept=' + accept, true, null);
    }

    /**
     * Add finance contract info with billing schedule
     * @param data : payload object
     * @param projectid : string - project id
     * @returns {Observable<any>}
     */
    addBills(data, projectid) {
        return this.baseCaller.patch(this.apiUrl + '/finance/' + projectid, true, data);
    }

    /**
     * Get authorized users list for PCR
     * @param projectid : string - project id
     * @returns {Observable<any>}
     */
    getAuthorizedUserForPCR(projectid: string) {
        return this.baseCaller.get(environment.basePath + "/users/authorizer/" + projectid, true);
    }

    /**
     * Create PCR
     * @param data : payload object
     * @returns {Observable<any>}
     */
    createPCR(data) {
        return this.baseCaller.post(this.apiUrl + '/create-pcr', true, data);
    }

    /**
     * Get issued requests list of PCR
     * @param projectid : string - project id
     * @param status : string - pending | approved | rejected | cancelled
     * @param substatus : string - invalid signed doc | return to sender | message for receiver | verify signed doc
     * @param srchKey : string - search key
     * @returns {Observable<any>}
     */
    getIssuedPCR(projectid: string, status: string, substatus: string, srchKey: string) {
        return this.baseCaller.get(environment.basePath + `/users/issued-pcr/${projectid}?status=${status}&subStatus=${substatus}&search=${srchKey}`, true);
    }

    /**
     * Get received requests list of PCR
     * @param projectid : string - project id
     * @param status : string - pending | approved | rejected | cancelled
     * @param substatus : string - invalid signed doc | return to sender | message for receiver | verify signed doc
     * @param srchKey : string - search key
     * @returns {Observable<any>}
     */
    getReceivedPCR(projectid: string, status: string, substatus: string, srchKey: string) {
        return this.baseCaller.get(environment.basePath + `/users/received-pcr/${projectid}?status=${status}&subStatus=${substatus}&search=${srchKey}`, true);
    }

    /**
     * Update a PCR info
     * @param data : payload object
     * @returns {Observable<any>}
     */
    updatePCR(data) {
        return this.baseCaller.patch(environment.basePath + "/users/update-pcr", true, data);
    }

    /**
     * Get attached file of a PCR
     * @param mediaid : string - file's media id
     * @returns {Observable<any>}
     */
    downloadPCRFile(mediaid) {
        return this._httpClient.get(environment.basePath + "/media/pcr/" + mediaid, { responseType: 'blob' });
    }

    /**
     * Upload signature document for a PCR
     * @param data : payload object
     * @param pcrid : string - PCR id
     * @returns {Observable<any>}
     */
    uploadSignatureDocForPCR(data, pcrid) {
        return this.baseCaller.patch(environment.basePath + `/users/pcr-signature-upload/${pcrid}`, true, data);
    }

    /**
     * Get detail of a PCR
     * @param pcrid : string - PCR id
     * @returns {Observable<any>}
     */
    getPCRDetailById(pcrid) {
        return this.baseCaller.get(environment.basePath + "/users/pcr/" + pcrid, true);
    }

    /**
     * Add billing info for Master Agreement and PCR
     * @param data : payload object
     * @param projectid : string - project id
     * @returns {Observable<any>}
     */
    updateBillInfo(data, projectid) {
        return this.baseCaller.patch(this.apiUrl + `/finance/update/${projectid}`, true, data);
    }

    /**
     * Download / Preview unsigned PCR document
     * @param pcrid : string - PCR id
     * @param preview : boolean
     * @returns {Observable<any>}
     */
    downloadUnsignedPCRDoc(pcrid, preview) {
        if (preview)
            return this._httpClient.get(environment.basePath + "/users/generate-pdf/" + pcrid + '?isSigned=true', { responseType: 'blob' });
        else
            return this._httpClient.get(environment.basePath + "/users/generate-pdf/" + pcrid, { responseType: 'blob' });
    }

    /**
     * Upload signature file for a PCR
     * @param data : payload object
     * @param pcrid : string - PCR id
     * @returns {Observable<any>}
     */
    uploadSignatureForPCR(data, pcrid) {
        return this.baseCaller.patch(environment.basePath + `/users/pcr-sign-upload/${pcrid}`, true, data);
    }

    /**
     * Get PCR's Terms & Conditions
     * @returns {Observable<any>}
     */
    getPCRTermsNConditions() {
        return this.baseCaller.get(environment.basePath + '/users/terms/get', true)
    }

    /**
     * Get sub-project members for a RFI
     * @param subpId : string - sub-project id
     * @returns {Observable<any>}
     */
    getSubProjectMembersForRFI(subpId: string) {
        return this.baseCaller.get(environment.basePath + `/users/project/members/${subpId}`, true);
    }

    /**
     * Create a RFI request
     * @param data : payload object
     * @returns {Observable<any>}
     */
    addRFI(data) {
        return this.baseCaller.post(environment.basePath + '/rfi/create', true, data);
    }

    /**
     * Get issued requests list of RFI
     * @param projectid : string - project id
     * @param currentPage : number - current page number
     * @param status : string - open | closed | cancelled
     * @param srchKey : string - search key
     * @returns {Observable<any>}
     */
    getIssuedRFIs(projectid, currentPage, status: string, srchKey: string) {
        return this.baseCaller.get(environment.basePath + `/rfi/issued/${projectid}?pageNumber=${currentPage}&pageSize=${this.pageSize}&status=${status}&search=${srchKey}`, true);
    }

    /**
     * Get received requests list of RFI
     * @param projectid : string - project id
     * @param currentPage : number - current page number
     * @param status : string - open | closed | cancelled
     * @param srchKey : string - search key
     * @returns {Observable<any>}
     */
    getReceivedRFIs(projectid, currentPage, status: string, srchKey: string) {
        return this.baseCaller.get(environment.basePath + `/rfi/received/${projectid}?pageNumber=${currentPage}&pageSize=${this.pageSize}&status=${status}&search=${srchKey}`, true);
    }

    /**
     * Update a RFI info
     * @param data : payload object
     * @param rfid : string - RFI id
     * @returns {Observable<any>}
     */
    updateRFI(data, rfid: string) {
        return this.baseCaller.patch(environment.basePath + `/rfi/${rfid}`, true, data);
    }

    /**
     * Get attached file of a RFI
     * @param mediaid : string - media id
     * @returns {Observable<any>}
     */
    downloadRFIFile(mediaid) {
        return this._httpClient.get(environment.basePath + "/media/rfi/" + mediaid, { responseType: 'blob' });
    }

    /**
     * Add RFI message
     * @param rfid : string - RFI id
     * @param data : payload object
     * @returns {Observable<any>}
     */
    addRFIComment(rfid, data) {
        return this.baseCaller.patch(environment.basePath + '/rfi/message/add/' + rfid, true, data);
    }

    /**
     * Convert HTML to plain text
     * @param html : string
     * @returns string
     */
    convertHTMLToPlainText(html) {
        var tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = html;
        return tempDivElement.textContent || tempDivElement.innerText || "";
    }

    /**
     * Get icon name for a file
     * @param fileType : string
     * @returns {Observable<any>}
     */
    getFileExtensionRFI(fileType) {
        if (fileType.includes('image/bmp') || fileType.includes('image/gif') || fileType.includes('image/jpeg') || fileType.includes('image/tiff') || fileType.includes('image/png')) return 'image';
        else if (fileType.includes('text/plain') || fileType.includes('rtf') || fileType.includes('wordprocessingml') || fileType.includes('msword') || fileType.includes('opendocument.text')) return 'doc';
        else if (fileType.includes('pdf')) return 'pdf';
        else if (fileType.includes('ms-excel') || fileType.includes('spreadsheetml') || fileType.includes('spreadsheet') || fileType.includes('csv')) return 'xls';
        else if (fileType.includes('ms-powerpoint') || fileType.includes('presentation')) return 'gd-ppt';
        else if (fileType.includes('video')) return 'db-video';
        else return 'general';
    }

    /**
     * Get messages of a RFI
     * @param rfid : string - RFI id
     * @param currentPage : number - current page number
     * @param pageSize : number
     * @returns {Observable<any>}
     */
    getRFIMessages(rfid, currentPage: number, pageSize: number) {
        return this.baseCaller.get(environment.basePath + `/rfi/message/${rfid}?pageNumber=${currentPage}&pageSize=${pageSize}`, true);
    }

    /**
     * Set status of a RFI
     * @param rfid : string - RFI id
     * @param data : payload object
     * @returns {Observable<any>}
     */
    setRFIStatus(rfid: string, data: any) {
        return this.baseCaller.patch(environment.basePath + '/rfi/status/' + rfid, true, data);
    }

    /**
     * Get detail of a RFI
     * @param rfiid : string - RFI id
     * @returns {Observable<any>}
     */
    getRFIDetailById(rfiid) {
        return this.baseCaller.get(environment.basePath + `/rfi/${rfiid}`, true);
    }

    /**
     * Get RFIs list to link with PCR
     * @param projectid : string - project id
     * @param currentPage : number - current page number
     * @param srckKey : string - search key
     * @returns {Observable<any>}
     */
    getRFIsToLink(projectid: string, currentPage: number, srckKey: string) {
        return this.baseCaller.get(environment.basePath + `/rfi/list/${projectid}?pageNumber=${currentPage}&pageSize=${this.pageSize}&search=${srckKey}`, true);
    }

    createNewStakeHolder(data, projectid) {
        return this.baseCaller.patch(this.apiUrl + '/stakeholder/add/' + projectid, true, data)
    }

    removeStakeholderFromProject(data) {
        return this.baseCaller.delete(this.apiUrl + '/stakeholder/remove', true, data);
    }

    updateStakeholderRoleInProject(projectid, data) {
        return this.baseCaller.patch(this.apiUrl + '/stakeholder/update/' + projectid, true, data);
    }

    updateStakeholderPermissionInProject(projectid, data) {
        return this.baseCaller.patch(this.apiUrl + '/stakeholder/permissions/update/' + projectid, true, data);
    }

    sendVerificationMailToStakeholder(userid: string) {
        return this.baseCaller.get(environment.basePath + '/users/resendverification/' + userid, true);
    }

    getAllStakeholders(currentPage: number, srchString?: string) {
        return this.baseCaller.get(this.apiUrl + `/stakeholder/list?page=${currentPage}&limit=${this.pageSize}&search=${srchString}`, true);
    }

    addExistingStakeholderInProject(projectid: string, data) {
        return this.baseCaller.patch(this.apiUrl + '/stakeholder/add/existing/' + projectid, true, data);
    }

    createSubmittal(data) {
        return this.baseCaller.post(environment.basePath + '/submittals/create', true, data);
    }

    getIssuedSubmittals(projectid, currentPage, srchKey: string, status: string, sortOrder: number) {
        return this.baseCaller.get(environment.basePath + `/submittals/issued/${projectid}?pageNumber=${currentPage}&pageSize=${this.pageSize}&search=${srchKey}&status=${status}${sortOrder == -1 ? '&sort=-1' : ''}`, true);
    }

    getSubmittalDetailById(id: string) {
        return this.baseCaller.get(environment.basePath + '/submittals/' + id, true);
    }

    updateSubmittal(data) {
        return this.baseCaller.patch(environment.basePath + '/submittals/update', true, data);
    }

    getReceivedSubmittals(projectid, currentPage, srchKey: string, status: string, sortOrder: number) {
        return this.baseCaller.get(environment.basePath + `/submittals/received/${projectid}?pageNumber=${currentPage}&pageSize=${this.pageSize}&search=${srchKey}&status=${status}${sortOrder == -1 ? '&sort=-1' : ''}`, true);
    }

    getSubmittalComments(id, currentPage: number) {
        return this.baseCaller.get(environment.basePath + `/submittals/comments/${id}?pageNumber=${currentPage}&pageSize=${this.pageSize}`, true);
    }

    addSubmittalComment(data, id) {
        return this.baseCaller.patch(environment.basePath + '/submittals/add-comment/' + id, true, data);
    }

    downloadSubmittalFile(mediaId) {
        return this._httpClient.get(environment.basePath + "/media/submittal/" + mediaId, { responseType: 'blob' });
    }

    getSubmittalsToLink(projectid, current: number, srchKey: string) {
        return this.baseCaller.get(environment.basePath + `/submittals/list/${projectid}?pageNumber=${current}&pageSize=${this.pageSize}&search=${srchKey}`, true);
    }

    sendUpdateToOwner(data, projectId) {
        return this.baseCaller.post(this.apiUrl + '/stakeholder/send-update/' + projectId, true, data);
    }

    setUpcomingTasksTime(projectId, weeks) {
        return this.baseCaller.patch(this.apiUrl + `/updates/${projectId}?weeks=${weeks}`, true, null);
    }
    //a5e end
}
