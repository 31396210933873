import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})
export class AddressService {
    constructor(private baseService: BaseService) { }

    /**
     * Get address from Google places api
     * @param input : string
     * @param sessionToken : google.maps.places.AutocompleteSessionToken
     * @returns {Observable<google.maps.places.QueryAutocompletePrediction[]>}
     */
    getAutoCompleteAddress(input: string, sessionToken: google.maps.places.AutocompleteSessionToken): Observable<google.maps.places.QueryAutocompletePrediction[]> {
        return new Observable((observer) => {
            const autoService = new google.maps.places.AutocompleteService();
            autoService.getPlacePredictions({
                input: input,
                sessionToken: sessionToken,
                // componentRestrictions: { country: ['ca'] }
            }, res => observer.next(res))
        });
    }

    /**
     * Get Geocode from Google places api
     * @param address : string
     * @returns {Observable<google.maps.GeocoderResult[]>}
     */
    getGeocode(address: string): Observable<google.maps.GeocoderResult[]> {
        return new Observable((observer) => {
            const autoService = new google.maps.Geocoder()
            autoService.geocode({ address: address }, res => observer.next(res))
        });
    }
}