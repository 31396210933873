import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import {
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatSelectModule,
    MatCheckboxModule,
    MatStepperModule,
    MatTooltipModule,
    MatRadioModule,
    MatSnackBarModule,
    MAT_SNACK_BAR_DEFAULT_OPTIONS
} from "@angular/material";

import { FuseSearchBarModule, FuseShortcutsModule } from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";

import { ToolbarComponent } from "app/layout/components/toolbar/toolbar.component";
import { SubtraidSharedModule } from "app/main/component/subtraidSharedModule";
// import { DuplicateBoardComponent } from "app/main/pages/dashboard/scrumboard/board/list/sub-project/duplicate-board/duplicate-board.component";
import { ExportDataComponent } from './export-data/export-data.component';
import { CreateProjectComponent } from './create-project/create-project.component';
import { PipesModule } from "app/main/pipes/pipes.module";
import { PopoverModule } from "ngx-smart-popover";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { ProjectSettingPopupComponent } from './project-setting-popup/project-setting-popup.component';
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { JoyrideModule } from "ngx-joyride";


@NgModule({
    declarations: [ToolbarComponent, ExportDataComponent, CreateProjectComponent, ProjectSettingPopupComponent],
    imports: [
        RouterModule,
        SweetAlert2Module.forRoot(),
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        SubtraidSharedModule,
        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        MatSelectModule,
        MatStepperModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatRadioModule,
        PipesModule,
        PopoverModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        JoyrideModule.forChild(),
        
    ],
    exports: [ToolbarComponent],
    entryComponents: [ExportDataComponent, CreateProjectComponent],
    providers: [{ provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000, verticalPosition: 'top' } }]
})
export class ToolbarModule { }
