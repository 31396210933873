import {  Component, AfterViewInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef , Output , Input ,EventEmitter} from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

// environment & constant
import { environment } from 'environments/environment.uat';
import {Constants} from '../../constants'

@Component({
  selector: 'app-update-card-form',
  templateUrl: './update-card-form.component.html',
  styleUrls: ['./update-card-form.component.scss']
})
export class UpdateCardFormComponent implements AfterViewInit , OnDestroy  {

  @ViewChild('cardInfo') cardInfo: ElementRef;


  //Stripe variable
  stripe:any;
  elements :any;
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;

  //constant
  basePath = Constants.basePath;
  submitting : boolean  = false;
  saveButtonDisabled : boolean = true;

  //Result emmitters

  //inputs

  @Input()
  private stripeSubscriptionId : string;

  @Output()
  errorEmitter: EventEmitter<any>;

  @Output()
  completeEmitter: EventEmitter<any>;

  constructor(
    private cd: ChangeDetectorRef,
    private httpClient: HttpClient
  ) {

    //stripe init
    this.stripe = Stripe(environment.stripeId);
    this.elements = this.stripe.elements();

    //emmitters init
    this.errorEmitter = new EventEmitter();
    this.completeEmitter = new EventEmitter();
  }

  ngAfterViewInit() {

    this.card = this.elements.create('card' , {hidePostalCode : true});
    this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    this.card.removeEventListener('change');
    this.card.destroy();
  }

  onChange({ error }) {

    if (error) {
      this.error = error.message;
      this.saveButtonDisabled = true;
    } else {
      this.error = null;
      this.saveButtonDisabled = false;
    }
    this.cd.detectChanges();
  }

  async onSubmit(ngform: NgForm) {

    //disable the form untill it finished submitting
    ngform.form.disable();
    this.submitting = true;

    let billing_details = ngform.form.value;

    //create paymet methtod
    const result  = await this.stripe.createPaymentMethod({
                                          type: 'card' ,
                                          card :this.card  ,
                                          billing_details : {
                                            address : {
                                              line1: billing_details.address_line1,
                                              line2: billing_details.address_line2,
                                              postal_code: billing_details.address_zip,
                                              state: billing_details.address_state,
                                              city: billing_details.address_city,
                                              country: billing_details.address_country
                                            } ,
                                            name : billing_details.name
                                          }
                                        });


    if(result.error){

          ngform.form.enable();
          this.submitting = false;
          console.log('Something is wrong:', result.error);
          this.errorEmitter.next(result.error.message)

    }else{

      //stripeSubscriptionId is require to make the attach new payment api call
      if(!this.stripeSubscriptionId){
        this.errorEmitter.next("Something went wrong, please try again later.")
        console.log("Missing stripe subscription Id when attempting to update the payment method.")
        ngform.form.enable();
        this.submitting = false;
        return
      }


      // attch paymetMethod to the user
      this.httpClient.patch(this.basePath + '/stripe/customer/attach_newPayment' , { payment_method : result.paymentMethod.id , stripeSubscriptionId: this.stripeSubscriptionId})
          .subscribe((data: any) => {

            ngform.form.enable();
            this.submitting = false;
            this.completeEmitter.next();

          },
          (err) =>{

            ngform.form.enable();
            this.submitting = false;
            this.errorEmitter.next(err.message || err);
          }
        );
    }
  }

}
