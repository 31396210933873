import { AgmCoreModule } from '@agm/core';
import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule, MatDatepickerModule, MatIconModule, MAT_TABS_CONFIG } from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatListModule } from '@angular/material/list';
//service serviceWorker
import { ServiceWorkerModule } from '@angular/service-worker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faAddressBook, faBell, faCalculator,
    faCalendarDay, faCamera, faCheck, faChevronCircleLeft, faChevronCircleRight, faChevronDown,
    faChevronUp,
    faClock, faEnvelope, faFilePowerpoint,
    faIdCard, faLock,

    faMapMarkedAlt, faMapMarkerAlt,
    faMoneyBillAlt,
    faPlus, faSearch,
    faSortAmountDown, faStreetView,
    faTimes, faTrashAlt, faUser, faUserCheck, faUserClock,
    faUsers,
    faUserFriends, faHandshake,
} from '@fortawesome/free-solid-svg-icons';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';
// fuse module's
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { TranslateModule } from '@ngx-translate/core';
import { AppComponent } from 'app/app.component';
import { fuseConfig } from 'app/fuse-config';
import { LayoutModule } from 'app/layout/layout.module';

import 'hammerjs';
//Subtraid services
import { AuthService } from '../app/main/services/auth.service';
import { ErrorInterceptor } from '../app/main/services/error-interceptor.service';
import { OnesignalService } from '../app/main/services/onesignal.service';
import { RequestService } from '../app/main/services/request.service';
import { TokenInterceptor } from '../app/main/services/token-interceptor.service';
import { UploadService } from '../app/main/services/upload.service';
import { environment } from '../environments/environment.uat';
//main routing
import { AppRoutingModule } from './app-routing.module';
import { NotificationService } from './main/pages/dashboard/notifications/notification.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import { JoyrideModule } from 'ngx-joyride';

@NgModule({
    declarations: [
        AppComponent          
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot(),
        // Material moment date module
        MatMomentDateModule,
        // Material
        MatButtonModule,
        MatIconModule,
        MatSlideToggleModule,
        MatCardModule,
        MatFormFieldModule,
        MatListModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        // App modules
        LayoutModule,
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDHmqPaFNYYxS3oMA4fnkUe07th-GOa0Mc',
            libraries: ['places']
        }),
        MatGoogleMapsAutocompleteModule.forRoot(),
        FontAwesomeModule,
        MatCarouselModule.forRoot(),
        MatNativeDateModule,
        MatDatepickerModule,
        NgxMatNativeDateModule,
        ScrollingModule,
        JoyrideModule.forRoot(),
    ],
    providers: [
        AuthService,
        UploadService,
        RequestService,
        NotificationService,
        OnesignalService,
        MatNativeDateModule,
        MatDatepickerModule,
        NgxMatNativeDateModule,
        // { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } }
    ],
    bootstrap: [
        AppComponent
    ],
})
export class AppModule {
    constructor() {
        library.add(faEnvelope, faLock, faUser, faSearch, faChevronDown, faChevronCircleLeft, faChevronCircleRight, faFilePowerpoint, faMapMarkerAlt, faTrashAlt,
            faAddressBook, faStreetView, faMoneyBillAlt, faClock, faUsers, faCalculator, faSortAmountDown, faBell, faTimes, faCheck, faPlus, faMapMarkedAlt,
            faCalendarDay, faChevronUp, faUserClock, faUserCheck, faCamera, faIdCard, faUserFriends, faHandshake);
    }
}
