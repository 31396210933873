import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
} from "@angular/core";
import { Observable } from "rxjs";

@Component({
    selector: "app-user-search",
    templateUrl: "./user-search.component.html",
    styleUrls: ["./user-search.component.scss"],
})
export class UserSearchComponent implements OnInit, OnChanges {
    @Input() contacts: any[];
    @Input() searchFn: Function;
    @Input() preSelected: any[];
    showSearchBox = false;
    search = "";
    filteredList = [];
    selectedList = [];
    @Output()
    Select = new EventEmitter();
    constructor() {}

    ngOnInit() {}

    ngOnChanges() {
        if (this.contacts && this.filteredList.length === 0) {
            this.filteredList = this.contacts;
        }
        if (this.preSelected && this.preSelected.length > 0) {
            this.selectedList = [];
            this.preSelected.forEach((el) => {
                let selected = this.contacts.find((con) => con._id === el._id);
                if (selected) {
                    this.selectedList.push(selected);
                }
            });
        }
        if (!this.searchFn) {
            this.searchFn = (contacts: any[], searchTerm: string) => {
                if (
                    contacts.length > 0 &&
                    searchTerm &&
                    searchTerm.length > 0
                ) {
                    return contacts.filter((el) =>
                        el.name
                            .toLocaleLowerCase()
                            .includes(searchTerm.toLocaleLowerCase())
                    );
                } else {
                    return contacts;
                }
            };
        }
    }

    selectUser(user) {
        let userIndex = this.filteredList.findIndex(
            (el) => el._id === user._id
        );
        if (userIndex >= 0) {
            this.selectedList.push(this.filteredList[userIndex]);
            this.filteredList.splice(userIndex, 1);
        }
        this.Select.next(this.selectedList);
    }

    removeContact(contact) {
        let userIndex = this.selectedList.findIndex(
            (el) => el._id === contact._id
        );
        if (userIndex >= 0) {
            this.selectedList.splice(userIndex, 1);
            this.filteredList.push(contact);
        }
        this.Select.next(this.selectedList);
    }

    filterContacts($search) {
        $search.subscribe((val) => {
            this.search = val;
            let employeesNotSelected = this.contacts.filter((el) => {
                return !this.selectedList.find((sel) => sel._id === el._id);
            });
            this.filteredList = this.searchFn(
                employeesNotSelected,
                this.search
            );
        });
    }
}
