import { CustomUserDropdownOptionComponent } from './custom-user-dropdown-option/custom-user-dropdown-option.component';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { MatButtonModule } from "@angular/material/button";
import {
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatRadioModule
} from "@angular/material";

import { SecuredImageComponent } from "app/main/component/secured-image/secured-image.component";
import { SecuredUrlComponent } from "app/main/component/secured-url/secured-url.component";
import { ImagePopupComponent } from "./image-popup/image-popup.component";
import { PaymentButtonComponent } from "./payment-button/payment-button.component";
import { InvoiceCompactComponent } from "./invoice/compact.component";
import { UpdateCardFormComponent } from "./update-card-form/update-card-form.component";
import { TextSearchComponent } from "./text-search/text-search.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MultiSelectorComponent } from "./multi-selector/multi-selector.component";
import { FuseSharedModule } from "@fuse/shared.module";
import { UserSearchComponent } from "./user-search/user-search.component";
import { ClickOutsideDirective } from './click-outside-directive/click-outside.directive';
import { CustomDropDownComponent } from './custom-drop-down/custom-drop-down.component';
// import { GetNamePipe } from "../pages/dashboard/timesheets/pipes/get-name.pipe";
import { AddressLookupComponent } from './address-lookup/address-lookup.component';
import { LoadingComponent } from './loading/loading.component';
import { DialogDeleteComponent } from '../pages/dashboard/scrumboard/dialog-delete/dialog-delete.component';
import { DuplicateBoardComponent } from '../pages/dashboard/scrumboard/project-board/duplicate-board/duplicate-board.component';
import { CreateBoardTemplateComponent } from '../pages/dashboard/scrumboard/project-board/create-board-template/create-board-template.component';
import { CopyToClipboardDirective } from './copy-to-clipboard/copy-to-clipboard.directive';
import { LoaderComponent } from './loader/loader.component';
import { DynamicOverlayContainerService } from './loader/dynamic-overlay-container.service';
import { SubtraidDeleteDialogComponent } from './subtraid-delete-dialog/subtraid-delete-dialog.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AutoCompleteInfiniteScrollComponent } from './auto-complete-infinite-scroll/auto-complete-infinite-scroll.component';
import { MentionTextInputComponent } from './mention-text-input/mention-text-input.component';
import { DirectiveModule } from '../directives/directive.module';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CustomSliderComponent } from './custom-slider/custom-slider.component';


@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        FormsModule,
        MatButtonModule,
        FontAwesomeModule,
        MatListModule,
        FuseSharedModule,
        MatIconModule,
        MatChipsModule,
        MatSelectModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatInputModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        SweetAlert2Module.forRoot(),
        DirectiveModule,
        CdkStepperModule
    ],
    declarations: [
        SecuredImageComponent,
        ImagePopupComponent,
        SecuredUrlComponent,
        PaymentButtonComponent,
        InvoiceCompactComponent,
        UpdateCardFormComponent,
        TextSearchComponent,
        MultiSelectorComponent,
        SecuredImageComponent,
        UserSearchComponent,
        ClickOutsideDirective,
        CustomDropDownComponent,
        // GetNamePipe,

        CustomUserDropdownOptionComponent,
        AddressLookupComponent,
        LoadingComponent,
        DialogDeleteComponent,
        DuplicateBoardComponent,
        CreateBoardTemplateComponent,
        CopyToClipboardDirective,
        LoaderComponent,
        SubtraidDeleteDialogComponent,
        AutoCompleteInfiniteScrollComponent,
        MentionTextInputComponent,
        CustomSliderComponent
    ],
    exports: [
        SecuredImageComponent,
        SecuredUrlComponent,
        PaymentButtonComponent,
        InvoiceCompactComponent,
        UpdateCardFormComponent,
        TextSearchComponent,
        MultiSelectorComponent,
        UserSearchComponent,
        ClickOutsideDirective,
        CustomDropDownComponent,
        // GetNamePipe,
        MatDatepickerModule,

        MatInputModule,
        CustomUserDropdownOptionComponent,
        AddressLookupComponent,
        LoadingComponent,
        DialogDeleteComponent,
        DuplicateBoardComponent,
        CreateBoardTemplateComponent,
        CopyToClipboardDirective,
        SubtraidDeleteDialogComponent,
        AutoCompleteInfiniteScrollComponent,
        MentionTextInputComponent,
        CustomSliderComponent
    ],
    entryComponents: [ImagePopupComponent, DialogDeleteComponent, DuplicateBoardComponent, CreateBoardTemplateComponent, LoaderComponent, SubtraidDeleteDialogComponent],
    providers: [DynamicOverlayContainerService],
})
export class SubtraidSharedModule { }
