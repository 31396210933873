import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { MediastorageService } from '../mediastorage.service'
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment.uat';

@Component({
  selector: 'app-secured-url',
  templateUrl: './secured-url.component.html',
  styleUrls: ['./secured-url.component.scss']
})
export class SecuredUrlComponent implements OnChanges {


  @Input() private href;
  @Input() mediaObject;
  @Input() classes: [];
  @Input() icon: boolean = false;
  public dataUrl$;

  basePath = environment.basePath;

  constructor(
    private httpClient: HttpClient,
    private domSanitizer: DomSanitizer,
    private mediastorageService: MediastorageService
  ) { }

  /**
   * Lifecycle hook: OnChanges
   */
  ngOnChanges(): void {
    this.dataUrl$ = this.loadUrl(this.href);
  }


  /**
   * Load url
   * @param url : string
   * @returns {Promise}
   */
  private loadUrl(url: string) {

    if (this.isDataURL(url)) {

      //sanitize url
      let sanitizeUrl = this.domSanitizer.bypassSecurityTrustUrl(url);

      return new Promise(function (resolve, reject) {

        resolve(sanitizeUrl);

      });
    }


    if (this.mediastorageService.getByID(url) !== null) {

      let sanitizeUrl = this.domSanitizer.bypassSecurityTrustUrl(this.mediastorageService.getByID(url));

      return new Promise(function (resolve, reject) {
        resolve(sanitizeUrl);
      });
    }

    // get url by media id
    return this.httpClient
      // load the image as a blob
      .get(this.basePath + '/media/' + url, { responseType: 'blob' })
      // create an object url of that blob that we can use in the src attribute
      .pipe(map(e => {

        let sanitizeUrl = URL.createObjectURL(e)
        this.mediastorageService.add(url, sanitizeUrl);
        return this.domSanitizer.bypassSecurityTrustUrl(sanitizeUrl)

      }, error => {

        return ''

      }));
  }

  /**
   * Validate for data url
   * @param s : string
   * @returns {boolean}
   */
  isDataURL(s) {

    // is the string being passed in are falsy just return  the string back
    if (!s) {
      return s
    }

    return !!s.match(/(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i);
  }

}
