import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {

    /**
     * Truncate string
     * @param value : string
     * @param limit : number
     * @returns {string}
     */
    transform(value: string, limit: number): string {
        if (value != null) {
            return value.length < limit
                ? value
                : value.slice(0, limit) + '...';
        }
        else return null;
    }
}