import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component( {
    selector: 'app-text-search',
    templateUrl: './text-search.component.html',
    styleUrls: [ './text-search.component.scss' ]
} )
export class TextSearchComponent implements OnInit, OnChanges {

    private searchText$ = new BehaviorSubject<string>( '' );
    searchText;

    @Input() placeholder;

    @Input() text: string;

    @Output()
    TextChange = new EventEmitter<BehaviorSubject<string>>();

    constructor () { }
    ngOnChanges(): void {
        this.searchText = this.text;
    }

    ngOnInit() {
    }

    onChange = ( event ) => {
        this.searchText = event;
        this.searchText$.next( this.searchText );
        this.TextChange.emit( this.searchText$ );
    }


}
