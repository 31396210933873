import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatListOption } from "@angular/material/list";
import { fuseAnimations } from "@fuse/animations";
import { FilterService } from "../../../main/services/filters.service";
import { BehaviorSubject } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component( {
    selector: "app-multi-selector",
    templateUrl: "./multi-selector.component.html",
    styleUrls: [ "./multi-selector.component.scss" ],
    animations: fuseAnimations,
} )
export class MultiSelectorComponent implements OnInit, OnChanges {
    @Input() displayName;
    @Input() items: any[];
    @Input() allowMultiple = true;
    @Input() searchText;
    @Input() maxItems = 9999;
    @Input() placeHolder

    @Input() isClear;


    @Output() SelectItem = new EventEmitter();
    isToggled = false;
    filteredList = [];
    selectedList = [];

    itemListControl = new FormControl( [] );
    showMemberSelect: boolean = false;
    assigneeControl;
    filteredUsers = [];
    itemSelectedTempVar = [];


    constructor (
        private filterService: FilterService
    ) { }

    ngOnInit() {
        this.filterService.clearFilters.subscribe( res => {
            if ( res ) {
                this.filteredList.forEach( i => {
                    if ( i.selected ) {
                        i.selected = false;
                    }

                } );
                this.assigneeControl = null;
                this.selectedList = [];
                this.SelectItem.next( this.selectedList );
            }
        } );

        this.filterService.inititialvalue.subscribe( res => {
            if ( res ) {

                let selected = this.filteredList.find( i => i.id == res );
                if ( selected ) {
                    selected.selected = true;

                    this.selectedList.push( res );
                    this.itemListControl.setValue( this.selectedList );
                    this.SelectItem.next( this.selectedList );
                }

            }
        } )



    }

    ngOnChanges() {

        this.filteredList =
            this.items && this.items.length > 0
                ? this.items.slice( 0, this.maxItems )
                : [];


    }

    toggle() {
        this.isToggled = !this.isToggled;
    }

    onSearch( text$: BehaviorSubject<string> ) {
        text$.pipe( debounceTime( 250 ) ).subscribe( ( st ) => {
            this.filterByName( st );
        } );
    }

    filterByName( name: string ) {
        this.filteredList = this.items
            .filter( ( it ) =>
                ( it.name as string ).toLowerCase().includes( name.toLowerCase() )
            )
            .slice( 0, this.maxItems );
    }

    onSelectToggle( sel: MatListOption[] ) {

        this.selectedList = sel.map( ( o ) => o.value );
        this.SelectItem.next( this.selectedList );
    }

    getItemName( id ) {
        return this.items.find( ( el ) => el.id === id ).name;
    }

    deselect( index ) {
        let temp = [ ...this.selectedList ];
        temp.splice( index, 1 );

        let delected = this.filteredList.find( i => i.id == this.selectedList[ index ] );
        delected.selected = false;

        this.selectedList = temp;
        this.itemListControl.setValue( this.selectedList );
        this.SelectItem.next( this.selectedList );

    }


    clearFilters(): void {
        this.selectedList = [];
        this.filteredList.forEach( i => i.selected = false );
        this.SelectItem.next( this.selectedList );
    }

    showItem( id ) {
        let itemFilted = this.filteredList.find( ( el ) => el.id === id );
        return itemFilted ? "initial" : "none";
    }

    clickOutSide() {
        this.showMemberSelect = false;
    }

    filterValues( $event ) {

    }

    selectItem( event ) {

        if ( !event.selected ) {
            this.itemSelectedTempVar.splice( this.itemSelectedTempVar.indexOf( event ), 1 );
        } else {
            if ( this.allowMultiple ) {
                this.itemSelectedTempVar.push( event );
            } else {
                this.filteredList.forEach( i => {
                    if ( i.id != event.id ) {
                        i.selected = false;

                    }
                } );


                this.itemSelectedTempVar = [];
                this.itemSelectedTempVar.push( event );
            }

        }
        this.selectedList = this.itemSelectedTempVar.map( ( o ) => o.id );
        this.SelectItem.next( this.selectedList );


    }
}


