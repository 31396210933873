import { DirectiveModule } from './../../../app/main/directives/directive.module';
import { NgModule } from '@angular/core';

import { FuseSidebarComponent } from './sidebar.component';

@NgModule( {
    imports: [ DirectiveModule ],
    declarations: [
        FuseSidebarComponent
    ],
    exports: [
        FuseSidebarComponent
    ]
} )
export class FuseSidebarModule {
}
