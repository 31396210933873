import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { CommunicationService } from 'app/main/services/communication.service';
import { ProjectsService } from 'app/main/services/projects.service';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-create-board-template',
  templateUrl: './create-board-template.component.html',
  styleUrls: ['./create-board-template.component.scss']
})
export class CreateBoardTemplateComponent implements OnInit, OnDestroy {

  private _unsubscribe = new Subject();

  @ViewChild('mDiv') mDiv: ElementRef;
  @ViewChild('errorSwal') errorSwal: SwalComponent;

  constructor(
    public matDialogRef: MatDialogRef<CreateBoardTemplateComponent>,
    private projectService: ProjectsService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private commService: CommunicationService,
  ) { }

  ngOnInit() {
  }

  /**
   * Lifecycle hook : OnDestroy
   */
  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  /**
   * Close this dialog box
   */
  close() {
    this.matDialogRef.close();
  }

  /**
   * Create template from board
   */
  saveAsTemplate() {
    this.commService.showHideLoader('Creating template...', true);
    // this.projectService.getProjectsForUser()
    //   .pipe(takeUntil(this._unsubscribe))
    //   .subscribe(res => {

    //     let temp = res.currentProjects.filter(el => el._id == this.data._id);

    //     let payload = {
    //       name: temp[0].name,
    //       labels: temp[0].labels,
    //       lists: temp[0].lists,
    //       cards: temp[0].cards
    //     };


    //     this.projectService.createTemplate(payload)
    //       .pipe(takeUntil(this._unsubscribe))
    //       .subscribe(res => {


    //         this.matDialogRef.close({result: true});
    //       });
    //   });
    let tempdata = cloneDeep(this.data);
    let tempcards = tempdata.cards.map(({ availableLabels, ...remaining }) => remaining);


    tempcards.forEach(el => {
      let att = [];
      el.attachments.forEach(obj => att.push(obj._id));
      el.attachments = att;

      let idMemberCard = [];
      el.idMembers.forEach(obj => idMemberCard.push(obj._id));
      el.idMembers = idMemberCard;

      let timeSh = [];
      el.timesheets.forEach(obj => timeSh.push(obj._id));
      el.timesheets = timeSh;


      el.comments.forEach(comment => {

        comment.idMember = comment.idMember._id;
      });

    });


    let payload2 = {
      name: tempdata.name,
      labels: tempdata.labels,
      lists: tempdata.lists.map(({ availableCards, availableProjects, checkItems, checkItemsChecked, completeCount, progress, ...remaining }) => remaining),
      cards: tempcards,
      subprojects: tempdata.subprojects
    }


    this.projectService.createTemplate(payload2)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(res => {
        this.commService.showHideLoader('', false);
        this.matDialogRef.close({ result: true });
      }, (err) => {
        this.commService.showHideLoader('', false);
        this.errorSwal.text = err.message || "Something went wrong!";
        this.errorSwal.show();
      });

  }

}
