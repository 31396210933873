import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sum'
})
export class SumPipe implements PipeTransform {

  /**
   * Sum of a property in an array of objects
   * @param item : any[]
   * @param key : string
   * @returns {number}
   */
  transform(item: any[], key?: string): number {
    return item.reduce((a, b) => a + b[key], 0);
  }

}
