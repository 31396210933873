import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [

    {
        id: "projects",
        title: "Projects",
        type: "item",
        icon: "project",
        url: "/dashboard/projects/boards",
    },
    {
        id: "contacts",
        title: "Contacts",
        type: "item",
        icon: "my-contacts",
        url: "/dashboard/contacts",
    },
    {
        id: "chat",
        title: "Chat",
        type: "item",
        icon: "custom-chat",
        url: "/dashboard/chat",
        badge: {
            title: "0",
            bg: "#ea1601",
            fg: "white",
        },
    },
    {
        id: "company",
        title: "Company",
        type: "item",
        icon: "company",
        url: "/dashboard/company",
    },
    {
        id: "timesheets",
        title: "Timesheets",
        hidden: false,
        type: "item",
        icon: "timesheets",
        url: "/dashboard/timesheets",
    },

    {
        id: "calendar",
        title: "Calendar",
        hidden: false,
        type: "item",
        icon: "calendar",
        url: "/dashboard/calendar",
    },
    {
        id: "gantt-chart",
        title: "Gantt Chart",
        hidden: false,
        type: "item",
        icon: "gantt",
        url: "/dashboard/gantt-chart",
    },
    // {
    //     id: "file-manager",
    //     title: "File Manager",
    //     hidden: false,
    //     type: "item",
    //     icon: "file",
    //     url: "/dashboard/file-manager",
    // },


];

export const navSubtraidEmployee: FuseNavigation[] = [
    {
        id: "improvement",
        title: "Platform Improvements",
        hidden: false,
        type: "item",
        icon: "bug",
        url: "/subtraid-employee/platform-improvements",
    },
    {
        id: "users",
        title: "Users",
        hidden: false,
        type: "item",
        icon: "users",
        url: "/subtraid-employee/employees",
    },
    {
        id: "basic-settings",
        title: "Basic Settings",
        hidden: false,
        type: "item",
        icon: "basic-setting",
        url: "/subtraid-employee/basic-settings",
    },

];

export const navStakeholder: FuseNavigation[] = [

    {
        id: "stakeholder-projects",
        title: "Projects",
        type: "item",
        icon: "project",
        url: "/stakeholder-users/projects",
    },

];

// export const navigation: FuseNavigation[] = [
//     {
//         id: "applications",
//         title: "Application",
//         translate: "NAV.APPLICATIONS",
//         type: "group",
//         children: [
//             {
//                 id: "home",
//                 title: "Home",
//                 hidden: true,
//                 type: "item",
//                 icon: "home",
//                 url: "/dashboard/home",
//             },
//             {
//                 id: "estimation",
//                 hidden: true,
//                 title: "Estimation Tool",
//                 type: "item",
//                 icon: "timeline",
//                 url: "/dashboard/estimation",
//             },
//             {
//                 id: "products",
//                 title: "Products",
//                 hidden: true,
//                 type: "item",
//                 icon: "add_shopping_cart",
//                 url: "/dashboard/products",
//             },
//             {
//                 id: "assemblies",
//                 title: "Assemblies",
//                 hidden: true,
//                 type: "item",
//                 icon: "build",
//                 url: "/dashboard/assemblies",
//             },
//             {
//                 id: "projects",
//                 title: "Projects",
//                 type: "item",
//                 icon: "far fa-folder",
//                 url: "/dashboard/projects",
//             },
//             {
//                 id: "contacts",
//                 title: "Contacts",
//                 type: "item",
//                 icon: "fas fa-user-friends",
//                 url: "/dashboard/contacts",
//             },
//             {
//                 id: "chat",
//                 title: "Chat",
//                 type: "item",
//                 icon: "far fa-comment-dots",
//                 url: "/dashboard/chat",
//                 badge: {
//                     title: "0",
//                     bg: "red",
//                     fg: "white",
//                 },
//             },
//             {
//                 id: "company",
//                 title: "Company",
//                 type: "item",
//                 icon: "far fa-building",
//                 url: "/dashboard/company",
//             },
//             {
//                 id: "timesheets",
//                 title: "Timesheets",
//                 hidden: false,
//                 type: "item",
//                 icon: "fas fa-history",
//                 url: "/dashboard/timesheets",
//             },
//             {
//                 id: "calendar",
//                 title: "Calendar",
//                 hidden: false,
//                 type: "item",
//                 icon: "fas fa-calendar",
//                 url: "/dashboard/calendar",
//             },
//         ],
//     },
// ];
