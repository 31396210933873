export class GooglemapLocation {
    name: string;
    address: string;
    unit: string;
    city: string;
    province: string;
    country: string;
    order_email: string;
    zip: string;
    lng: string;
    lat: string;
    country_code: string;
    province_code: string;
    /**
     * Constructor
     *
     * @param location
     */
    constructor(location) {
        this.name = location.name || '';
        this.address = location.address || '';
        this.unit = location.unit || '';
        this.city = location.city || '';
        this.province = location.province || '';
        this.country = location.country || '';
        this.order_email = location.orderEmail || '';
        this.zip = location.zip || '';
        this.lng = location.lng || '';
        this.lat = location.lat || '';
        this.country_code = this.country_code || '';
        this.province_code = this.province_code || '';
    }
}
