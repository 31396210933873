import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import { ContentComponent } from 'app/layout/components/content/content.component';
import { SubtraidSharedModule } from 'app/main/component/subtraidSharedModule';
import { NotificationsModule } from 'app/main/pages/dashboard/notifications/notifications.module';

@NgModule({
    declarations: [
        ContentComponent
    ],
    imports     : [
        RouterModule,
        FuseSharedModule,
        MatIconModule
    ],
    exports     : [
        ContentComponent
    ]
})
export class ContentModule
{
}
