import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MediastorageService } from 'app/main/component/mediastorage.service';
import { BaseService } from 'app/main/services/base.service';
import { environment } from 'environments/environment.uat';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FMToken } from './fmtoken.model';

@Injectable({
  providedIn: 'root'
})
export class FileManagementService {

  basePath = environment.basePath + '/file-management';
  public dataLimit = 30;
  private onViewTypeChange = new BehaviorSubject('card');
  public viewType = this.onViewTypeChange.asObservable();





  constructor(
    private baseService: BaseService,
    private httpService: HttpClient,
    private mediastorageService: MediastorageService,
    private domSanitizer: DomSanitizer,
  ) { }


  loginToFM(fmType) {
    return this.baseService.get(this.basePath + '/sign-in?drive=' + fmType, true);
  }

  getFMToken(code, fmType) {
    return this.baseService.get(this.basePath + `/auth?drive=${fmType}&code=` + code, true, null);
  }

  getUserInfo(fmType): Observable<any> {
    let token = this.getToken(fmType);


    let header: any = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("barereToken"),
        accesstoken: token.access_token
      }
    };
    if (fmType != 'onedrive')
      header.headers.refreshtoken = token.refresh_token;
    if (fmType == 'googledrive')
      header.headers.expirydate = token.expiry_date.toString();



    console.log(header);
    return this.httpService.get(this.basePath + '/logged-in/user?drive=' + fmType, header);

  }

  getToken(fmType) {
    let tokenType = (fmType == 'onedrive' ? 'odToken' : (fmType == 'dropbox' ? 'dbToken' : 'gdToken'));
    if (localStorage.getItem(tokenType) == null)
      return null;
    else
      return JSON.parse(localStorage.getItem(tokenType));
  }

  isTokenExpired(fmType) {
    let token = this.getToken(fmType);
    console.log('token expired=', Date.now() < token.expiry_date);
    return token == null ? true : !(Date.now() < token.expiry_date);
  }

  getRefreshToken(fmType, header): Observable<any> {
    return this.httpService.get(this.basePath + '/access-token?drive=' + fmType, header)
  }

  refreshAccessToken(fmType) {
    let token = this.getToken(fmType);

    if (fmType == 'onedrive') {
      let header: any = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("barereToken"),
          refreshtoken: token.refresh_token
        }
      };
      this.getRefreshToken(fmType, header)
        .subscribe(res => {
          console.log('refresh=', res);
          let token: any = {
            access_token: res.access_token,
            expires_in: res.expires_in,
            refresh_token: res.refresh_token,
            token_type: res.token_type,
            tos: Date.now(),
            expiry_date: Date.now() + (res.expires_in * 1000)
          };
          localStorage.setItem("odToken", JSON.stringify(token));
        });
    }

  }

  logoutFM(fmType): Observable<any> {

    let token = this.getToken(fmType);

    if (fmType == 'dropbox') {

      let header: any = {
        headers: {

          refreshtoken: token.refresh_token,
          accesstoken: token.access_token
        }
      }

      return this.httpService.post(this.basePath + '/sign-out?drive=' + fmType, null, header);
    }
    else if (fmType == 'googledrive') {
      let header: any = {
        headers: {

          refreshtoken: token.refresh_token,
        }

      }
      return this.httpService.post(this.basePath + '/sign-out?drive=' + fmType, null, header);
    }
    else {
      return this.httpService.post(this.basePath + '/sign-out?drive=' + fmType, null, { headers: {} });
    }
  }

  getFMHeaders(fmtype): any {

    let token: FMToken = this.getToken(fmtype);
    let header: any = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("barereToken"),
      }
    };
    if (fmtype == 'dropbox') {

      header.headers.accesstoken = token.access_token;
      header.headers.refreshtoken = token.refresh_token;
    }
    return header;
  }

  getFolderFileList(fmtype, data): Observable<any> {
    let endpoint = "";

    if (fmtype == 'dropbox') {
      endpoint = this.basePath + '/dropbox/_files?drive=dropbox';

    }

    data.limit = this.dataLimit;
    return this.httpService.post(endpoint, data, this.getFMHeaders(fmtype));
  }

  createFolder(fmtype, data): Observable<any> {
    let endpoint = "";

    if (fmtype == 'dropbox') {
      endpoint = this.basePath + '/dropbox/create-folder?drive=dropbox';

    }

    return this.httpService.post(endpoint, data, this.getFMHeaders(fmtype));
  }

  search(fmtype, data): Observable<any> {
    let endPoint = '';
    if (fmtype == 'dropbox') endPoint = this.basePath + '/dropbox/files/_search?drive=dropbox';

    data.limit = this.dataLimit;
    return this.httpService.post(endPoint, data, this.getFMHeaders(fmtype));
  }

  changeViewType(data) {
    this.onViewTypeChange.next(data);
  }



  getSharedFolders(fmType, data): Observable<any> {
    let endPoint = '';
    if (fmType == 'dropbox') endPoint = this.basePath + '/dropbox/_shared-folders?drive=dropbox';

    data.limit = this.dataLimit;
    return this.httpService.post(endPoint, data, this.getFMHeaders(fmType));
  }

  getSharedFiles(fmType, data): Observable<any> {
    let endPoint = '';
    if (fmType == 'dropbox') endPoint = this.basePath + '/dropbox/_shared-files?drive=dropbox';


    data.limit = this.dataLimit;
    return this.httpService.post(endPoint, data, this.getFMHeaders(fmType));
  }

  moveToTrash(fmType, path): Observable<any> {
    let endPoint = '';
    if (fmType == 'dropbox') endPoint = this.basePath + '/dropbox/delete-file?drive=dropbox&path=' + path;

    return this.httpService.delete(endPoint, this.getFMHeaders(fmType));
  }

  getDownloadUrl(fmType, path): Observable<any> {
    let endPoint = '';
    // if (type == 'file') {
    if (fmType == 'dropbox') endPoint = this.basePath + '/dropbox/download-file?drive=dropbox&path=' + path;
    // }
    // else if (type == 'folder') {
    //   if (fmType == 'dropbox') endPoint = this.basePath + '/dropbox/download-folder?drive=dropbox&path=' + path;
    // }

    return this.httpService.get(endPoint, this.getFMHeaders(fmType));
  }




  downloadFolder(fmType, path): Observable<any> {
    let endPoint = '';
    if (fmType == 'dropbox') endPoint = this.basePath + '/dropbox/download-folder?drive=dropbox&path=' + path;

    let header = this.getFMHeaders(fmType);

    return this.httpService.get(endPoint, { headers: header.headers, responseType: 'arraybuffer' });
  }

  uploadFile(fmType, file: File, path) {
    let endPoint = '';
    if (fmType == 'dropbox') endPoint = this.basePath + '/dropbox/upload-file?drive=dropbox&path=' + path + (path == '/' ? '' : '/');

    let header = this.getFMHeaders(fmType);

    const formData = new FormData();
    formData.append('file', file, file.name);
    // formData.append("name", file.name);

    // let hd=new HttpHeaders({
    //   // 'enctype':'multipart/form-data',
    //   Authorization: header.headers.Authorization,
    //   accesstoken:header.headers.accesstoken,
    //   refreshtoken:header.headers.refreshtoken,

    // });


    return this.httpService.post(endPoint, formData, { headers: header.headers, reportProgress: true, observe: "events" });
  }

  getContacts(searchString, pageNo): Observable<any> {
    let endPoint = this.basePath + `/suggested-users?searchQuery=${searchString}&pageNumber=${pageNo}&pageSize=20`;
    return this.httpService.get(endPoint, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("barereToken"),
      }
    });
  }


  shareFile(data, fmType): Observable<any> {
    let endPoint = '';
    if (fmType == 'dropbox') endPoint = this.basePath + '/dropbox/share-file?drive=dropbox';
    return this.httpService.post(endPoint, data, this.getFMHeaders(fmType));
  }

  getShareLink(fmType, data): Observable<any> {
    let endPoint = '';
    if (fmType == 'dropbox') endPoint = this.basePath + '/dropbox/share-link?drive=dropbox';
    return this.httpService.post(endPoint, data, this.getFMHeaders(fmType));
  }

  shareFolder(fmType, data): Observable<any> {
    let endPoint = '';
    if (fmType == 'dropbox') endPoint = this.basePath + '/dropbox/share-folder?drive=dropbox';
    return this.httpService.post(endPoint, data, this.getFMHeaders(fmType));
  }

  checkFolderStatus(fmType, data): Observable<any> {
    let endPoint = '';
    if (fmType == 'dropbox') endPoint = this.basePath + '/dropbox/share-folder/status?drive=dropbox';
    return this.httpService.post(endPoint, data, this.getFMHeaders(fmType));
  }

  addMemberToSharedFolder(fmType, data): Observable<any> {
    let endPoint = '';
    if (fmType == 'dropbox') endPoint = this.basePath + '/dropbox/share-folder/add-members?drive=dropbox';
    return this.httpService.post(endPoint, data, this.getFMHeaders(fmType));
  }

  addToDropbox(data): Observable<any> {
    let endPoint = this.basePath + '/dropbox/mount-shared-folder?drive=dropbox';
    return this.httpService.post(endPoint, data, this.getFMHeaders('dropbox'));
  }

  getSharedFileMembers(fmType, data): Observable<any> {
    let endPoint = '';
    if (fmType == 'dropbox') endPoint = this.basePath + '/dropbox/shared-file/_members?drive=dropbox';
    return this.httpService.post(endPoint, data, this.getFMHeaders(fmType));
  }

  getSharedFolderMembers(fmType, data): Observable<any> {
    let endPoint = '';
    if (fmType == 'dropbox') endPoint = this.basePath + '/dropbox/shared-folder/_members?drive=dropbox';
    return this.httpService.post(endPoint, data, this.getFMHeaders(fmType));
  }

  setDBSharedFolderMemberAccess(data): Observable<any> {
    let endPoint = this.basePath + '/dropbox/shared-folder/update-member-access?drive=dropbox';
    return this.httpService.patch(endPoint, data, this.getFMHeaders('dropbox'));
  }

  removeDBMemberFromSharedFolder(data): Observable<any> {
    let endPoint = this.basePath + '/dropbox/shared-folder/remove-member-access?drive=dropbox';
    return this.httpService.patch(endPoint, data, this.getFMHeaders('dropbox'));
  }

  getDBFileMetaData(data): Observable<any> {
    let endPoint = this.basePath + '/dropbox/_file-metadata?drive=dropbox';
    return this.httpService.post(endPoint, data, this.getFMHeaders('dropbox'));
  }

  getDBFolderMetaData(data): Observable<any> {
    let endPoint = this.basePath + '/dropbox/_folder-metadata?drive=dropbox';
    return this.httpService.post(endPoint, data, this.getFMHeaders('dropbox'));
  }

  setDBSharedFileMemberAccess(data): Observable<any> {
    let endPoint = this.basePath + '/dropbox/shared-file/update-member-access?drive=dropbox';
    return this.httpService.patch(endPoint, data, this.getFMHeaders('dropbox'));
  }

  removeDBMemberFromSharedFile(data): Observable<any> {
    let endPoint = this.basePath + '/dropbox/shared-file/remove-member-access?drive=dropbox';
    return this.httpService.patch(endPoint, data, this.getFMHeaders('dropbox'));
  }

  unshareFile(fmType,data): Observable<any> {
    let endPoint = '';
    if (fmType == 'dropbox') endPoint = this.basePath + '/dropbox/unshare-file?drive=dropbox';
    return this.httpService.patch(endPoint, data, this.getFMHeaders(fmType));
  }

  unshareFolder(fmType,data): Observable<any> {
    let endPoint = '';
    if (fmType == 'dropbox') endPoint = this.basePath + '/dropbox/unshare-folder?drive=dropbox';
    return this.httpService.patch(endPoint, data, this.getFMHeaders(fmType));
  }

}
