import { CdkStepper } from '@angular/cdk/stepper';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-slider',
  templateUrl: './custom-slider.component.html',
  styleUrls: ['./custom-slider.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: CustomSliderComponent }],
})
export class CustomSliderComponent extends CdkStepper {

  

}
