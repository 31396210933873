import { Directionality } from '@angular/cdk/bidi';
import { Overlay, OverlayKeyboardDispatcher, OverlayPositionBuilder, OverlayRef, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { Injectable, Renderer2, ComponentFactoryResolver, Injector, NgZone, Inject, RendererFactory2 } from '@angular/core';
import { DynamicOverlayContainerService } from './dynamic-overlay-container.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicOverlayService extends Overlay {

  private readonly _dynamicOverlayContainer: DynamicOverlayContainerService;
  private renderer: Renderer2;

  constructor(
    scrollStrategies: ScrollStrategyOptions,
    _overlayContainer: DynamicOverlayContainerService,
    _componentFactoryResolver: ComponentFactoryResolver,
    _positionBuilder: OverlayPositionBuilder,
    _keyboardDispatcher: OverlayKeyboardDispatcher,
    _injector: Injector,
    _ngZone: NgZone,
    @Inject(DOCUMENT) _document: any,
    _directionality: Directionality,

    rendererFactory: RendererFactory2
  ) {
    //initalize variables
    super(
      scrollStrategies,
      _overlayContainer,
      _componentFactoryResolver,
      _positionBuilder,
      _keyboardDispatcher,
      _injector,
      _ngZone,
      _document,
      _directionality
    );
    this.renderer = rendererFactory.createRenderer(null, null);

    this._dynamicOverlayContainer = _overlayContainer;
  }

  /**
   * Set container element on overlay using css
   * @param containerElement : HTMLElement
   */
  private setContainerElement(containerElement: HTMLElement): void {
    this.renderer.setStyle(containerElement, 'transform', 'translateZ(0)');
    this._dynamicOverlayContainer.setContainerElement(containerElement);
  }

  /**
   * Set default configuration for container element
   * @param containerElement : HTMLElement
   * @param backdropClass : string
   * @returns {OverlayRef}
   */
  public createWithDefaultConfig(containerElement: HTMLElement, backdropClass: string): OverlayRef {
    this.setContainerElement(containerElement);
    return super.create({
      scrollStrategy: this.scrollStrategies.block(),
      positionStrategy: this.position()
        .global()
        .centerHorizontally()
        .centerVertically(),
      hasBackdrop: true,
      backdropClass: backdropClass == null || backdropClass == '' ? 'bgdrop' : backdropClass
    });
  }
}
