import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree, CanActivate } from '@angular/router';

import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthEmployeeGuard implements CanLoad, CanActivate {

  constructor(
    private router: Router,
    private AuthService: AuthService
  ) {

  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    // let url: string = route.path;
    
    
    if (this.AuthService.isSubtraidEmployee()) {
      
      return true;
    }
    else {
      // this.router.navigate(['login']);
      this.router.navigate(['admin-login']);
      
      return false;
    }

    // if (url == "subtraid-employee")
    //   return true;
    // else return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.AuthService.isLogin() && this.AuthService.isSubtraidEmployee()) {     

      return true;
    } else {
      
      this.router.navigate(["admin-login"]);
      return false;
    }
  }

}