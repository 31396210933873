import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../app/main/services/auth.guard';
import { AfterLoginGuard } from '../app/main/services/after-login.guard';
import { AuthEmployeeGuard } from './main/services/auth-employee.guard';
import { SubtraidEmployeeGuard } from './main/services/subtraid-employee.guard';
import { AuthStakeholderGuard } from './main/services/auth-stakeholder.guard';
import { StakeholderUserGuard } from './main/services/stakeholder-user.guard';

const appRoutes: Routes = [

  {
    path: 'login',
    loadChildren: 'app/main/pages/login/login-2.module#Login2Module',
    canActivate: [AfterLoginGuard]
  },
  {
    path: 'admin-login',
    loadChildren: 'app/main/pages/subtraid-admin-login/subtraid-admin-login.module#SubtraidAdminLoginModule',
    canActivate: [AfterLoginGuard]
  },
  {
    path: 'stakeholder-login',
    loadChildren: 'app/main/pages/stakeholder-login/stakeholder-login.module#StakeholderLoginModule',
    canActivate: [AfterLoginGuard]
  },
  {
    path: 'register',
    loadChildren: 'app/main/pages/register/register-2.module#Register2Module',
    canActivate: [AfterLoginGuard]
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
    canActivate: [AfterLoginGuard]
  },
  {
    path: 'confirmation/:token',
    loadChildren: 'app/main/pages/comfirmation/comfirmation.module#ComfirmationModule',
    canActivate: [AfterLoginGuard]

  }, {
    path: 'forgotPassword',
    loadChildren: 'app/main/pages/forgot-password-2/forgot-password-2.module#ForgotPassword2Module',
    canActivate: [AfterLoginGuard]

  },
  {
    path: 'resetpassword/:token',
    loadChildren: 'app/main/pages/resetpassword/resetpassword.module#ResetpasswordModule',
    canActivate: [AfterLoginGuard]

  },
  {
    path: 'dashboard',
    loadChildren: 'app/main/pages/dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthGuard],

  },
  {
    path: 'subtraid-employee',
    loadChildren: 'app/main/pages/subtraid-employee/subtraid-employee.module#SubtraidEmployeeModule',
    canLoad: [AuthEmployeeGuard],
    canActivate: [SubtraidEmployeeGuard],

  },
  {
    path: 'stakeholder-users',
    loadChildren: 'app/main/pages/stakeholder-users/stakeholder-users.module#StakeholderUsersModule',
    canLoad: [AuthStakeholderGuard],
    canActivate: [StakeholderUserGuard],

  },
  {
    path: '404',
    loadChildren: 'app/main/pages/404/error-404.module#Error404Module',
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    loadChildren: 'app/main/pages/404/error-404.module#Error404Module',
    canActivate: [AuthGuard]
  }
];


@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
