import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ProjectPermissionSettings } from 'app/main/pages/dashboard/scrumboard/permissions';
import { Project } from 'app/main/pages/dashboard/scrumboard/project.model';
import { AuthService } from 'app/main/services/auth.service';
import { ProjectsService } from 'app/main/services/projects.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-project-setting-popup',
  templateUrl: './project-setting-popup.component.html',
  styleUrls: ['./project-setting-popup.component.scss']
})
export class ProjectSettingPopupComponent implements OnInit, OnDestroy {

  showSub: boolean = false;
  svgIcon: string = "down-arrow-svg";
  selected = 0;


  @Output() onTimesheetSettingChange = new EventEmitter();
  subscribed: boolean = false;
  board: Project;
  projectID: string;
  projSetting: ProjectPermissionSettings;
  projSettingBreakPoint = false;

  private _unsubscribe = new Subject();

  constructor(
    private projectService: ProjectsService,
    private breakpointObsv: BreakpointObserver,
    private authService: AuthService
  ) {
    //get breakpoint to minimize button size
    this.breakpointObsv
      .observe(['(max-width: 1153px)'])
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          //less than 1153          
          this.projSettingBreakPoint = true;
        } else {
          //more than 1153        
          this.projSettingBreakPoint = false;
        }
      });
  }

  /**
   * Lifecycle hook: OnInit
   */
  ngOnInit() {
    //get settings data
    this.projectService.projectSetting
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(res => {

        this.projSetting = res;
        // this.projectID = res.projectId;

        this.selected = res.settings.timesheetLevel;
        // this.subscribed = res.settings.subscribed;
        // this.subscribed=this.authService.getBarereToken().isPaidAccount;
      });
  }

  /**
   * Lifecycle hook: OnDestroy
   */
  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  /**
   * Show sub-menu
   */
  showSubMenu() {
    this.showSub = !this.showSub;
    this.showSub ? this.svgIcon = 'up-arrow-svg' : this.svgIcon = 'down-arrow-svg';
  }

  /**
   * Set timesheet setting
   * @param setting : number - 1=In Project, 2=In Card, 3=Both
   * @param checked 
   */
  setSetting(setting, checked) {

    this.projectService.updateProjectTimesheetSetting(this.projSetting.projectId, { timesheetLevel: setting })
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(res => {

        this.selected = setting;

        this.projSetting.settings.timesheetLevel = setting;
        this.projectService.refreshProjectSetting(this.projSetting);

        this.onTimesheetSettingChange.emit();
      });

  }

}

