export const environment = {
    production: false,
    hmr: false,
    qa: false,
    uat: true,
    basePath: 'https://eduversity.ai:3000',
    basePathNonApi: 'https://uat.platform.subtraid.com',

    // basePath  : 'http://3.111.69.255:3000',
    // basePath  : 'https://uat.api.subtraid.com',

    // basePath: 'http://localhost:3000',
    // basePathNonApi: 'https://localhost:4200',
    stripeId: "pk_test_8DeZizJtK5JnxBrqNhpCfnHQ00fFRvUp4A",
    availablePlans: [
        {
            stripePlanId: "plan_H0sxk7glLM7u0q",
            name: "Beta Premium Plan",
            isBestValue: false,
            price: 8000,
            billing_interval: "Yearly",
            note: "Billed annually after 30 days free trial"
        },
        {
            stripePlanId: "plan_GnjGGXGk8ahMZk",
            name: "Beta User Plan",
            isBestValue: true,
            price: 6000,
            billing_interval: "year",
            note: "Billed annually after 30 days free trial"
        }
    ],
    endPoints: {
        projects: "/projects",
        user: "/user",
        projectsOnly: "/users/projects-list"
    }
};
