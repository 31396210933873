import { Component, Input, OnInit } from '@angular/core';

@Component( {
  selector: 'app-custom-user-dropdown-option',
  templateUrl: './custom-user-dropdown-option.component.html',
  styleUrls: [ './custom-user-dropdown-option.component.scss' ]
} )
export class CustomUserDropdownOptionComponent implements OnInit {

  @Input() user: any;
  constructor () { }

  ngOnInit() {
  }

}
