import { Component, ViewEncapsulation , Input } from '@angular/core';

@Component({
    selector     : 'invoice-compact',
    templateUrl  : './compact.component.html',
    styleUrls    : ['./compact.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class InvoiceCompactComponent
{

    @Input() invoice;

    constructor(

    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------


}
